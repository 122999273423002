import React from 'react';
import { contactAddress, contactEmail, contactMapLink, contactPhone } from '../../../config';

const ContactArea = () => {
   return (
      <>
         <section className="contact__area">
            <div className="container-fluid p-0">
               <div className="row g-0">
                  <div className="col-xl-12">
                     <div className="contact__map">
                        <iframe title='contact' src={contactMapLink}></iframe>
                        <div className="contact__wrapper d-md-flex justify-content-between wow fadeInUp" data-wow-delay=".3s">
                           <div className="contact__info mr-100">
                              <h3>Get in Touch</h3>
                              <ul>
                                 <li>
                                    <h4>Address</h4>
                                    <p dangerouslySetInnerHTML={{ __html: contactAddress }}></p>
                                 </li>
                                 <li>
                                    <h4>call us</h4>
                                    <p><a href={`tel:${contactPhone}`}>{contactPhone}</a></p>
                                 </li>
                                 <li>
                                    <h4>Email Address</h4>
                                    <p><a href={`mailto:${contactEmail}`}>{contactEmail}</a></p>
                                 </li>
                              </ul>
                           </div>
                           <div className="contact__form">
                              <form >
                                 <input type="text" required placeholder="Your Name" />
                                 <input type="email" required placeholder="Your Email" />
                                 <textarea required placeholder="Your Message"></textarea>
                                 <button type="submit" className="z-btn">Send Message</button>
                              </form>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default ContactArea;
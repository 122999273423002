import React from 'react';
import { useParams } from 'react-router-dom';
import CommonCtaArea from '../../components/CommonCtaArea/CommonCtaArea';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeOneHeader from '../Home/HomeOneHeader/HomeOneHeader';

// ALL SERVICE PAGES WILL BE HERE
import WokforceAutomation from './serviceDetails/SmartFactory/WokforceAutomation';
import FrontlineAutomation from './serviceDetails/SmartFactory/FrontlineAutomation';

import FrontlineAPI from './serviceDetails/SmartFactory/FrontlineAPI';
import FactoryAutomation from './serviceDetails/SmartFactory/FactoryAutomation';
import DigitalRoadmapping from './serviceDetails/SmartFactory/DigitalRoadmapping';

import NetZero from './serviceDetails/NetZero/index';

import Microalgae from './serviceDetails/CarbonCapture/Microalgae';
import Membarane from './serviceDetails/CarbonCapture/Membarane';

import WorkforceForecasting from './serviceDetails/Solutions/WorkforceForecasting';
import COGSModeling from './serviceDetails/Solutions/COGSModeling';
import FactoryDigitalTwin from './serviceDetails/Solutions/FactoryDigitalTwin';
// ALL SERVICE PAGES WILL BE HERE

const Services = () => {
   const { sectionID, typeID } = useParams();

   function showTitle(value) {
      let ReturnValue = '';
      switch (value) {
         case 'WokforceAutomation': ReturnValue = 'Wokforce Automation'; break;
         case 'FrontlineAutomation': ReturnValue = 'Frontline Automation'; break;

         case 'FrontlineAPI': ReturnValue = 'Frontline API'; break;
         case 'FactoryAutomation': ReturnValue = 'Factory Automation'; break;
         case 'DigitalRoadmapping': ReturnValue = 'Digital Roadmapping'; break;

         case 'NetZero': ReturnValue = 'Net Zero'; break;
         case 'GreenHydrogenTransition': ReturnValue = 'Net Zero'; break;
         case 'EVChargingStation': ReturnValue = 'Net Zero'; break;
         case 'MicrogridAnalysis': ReturnValue = 'Net Zero'; break;
         case 'AmmoniaAnalysis': ReturnValue = 'Net Zero'; break;
         case 'DryMethaneReforming': ReturnValue = 'Net Zero'; break;

         case 'Microalgae': ReturnValue = 'Microalgae'; break;
         case 'Membarane': ReturnValue = 'Membarane'; break;

         case 'WorkforceForecasting': ReturnValue = 'Workforce Forecasting'; break;
         case 'COGSModeling': ReturnValue = 'COGS Modeling'; break;
         case 'FactoryDigitalTwin': ReturnValue = 'Factory Digital Twin'; break;
         default: ReturnValue = 'Net Zero'; break;
      }
      return ReturnValue;
   }

   function showSubTitle(value) {
      let ReturnValue = '';
      switch (value) {
         case 'SmartFactory':
            ReturnValue = 'Smart Factory';
            break;
         case 'NetZero':
            ReturnValue = 'Net Zero';
            break;
         case 'CarbonCapture':
            ReturnValue = 'Carbon Capture';
            break;
         case 'Solutions':
            ReturnValue = 'Solutions';
            break;
         default:
            ReturnValue = 'Section';
            break;
      }
      return ReturnValue;
   }

   return (
      <>
         <PageHelmet pageTitle={showTitle(typeID)} />
         <HomeOneHeader />
         <CommonPageHeader subtitle={showSubTitle(sectionID)} title={showTitle(typeID)} />

         {sectionID === 'SmartFactory' && typeID === 'WokforceAutomation' && <WokforceAutomation />}
         {sectionID === 'SmartFactory' && typeID === 'FrontlineAutomation' && <FrontlineAutomation />}

         {sectionID === 'SmartFactory' && typeID === 'FrontlineAPI' && <FrontlineAPI />}
         {sectionID === 'SmartFactory' && typeID === 'FactoryAutomation' && <FactoryAutomation />}
         {sectionID === 'SmartFactory' && typeID === 'DigitalRoadmapping' && <DigitalRoadmapping />}

         {sectionID === 'NetZero' && <NetZero typeID={typeID} sectionID={sectionID} />}

         {sectionID === 'CarbonCapture' && typeID === 'Microalgae' && <Microalgae />}
         {sectionID === 'CarbonCapture' && typeID === 'Membarane' && <Membarane />}

         {sectionID === 'Solutions' && typeID === 'WorkforceForecasting' && <WorkforceForecasting />}
         {sectionID === 'Solutions' && typeID === 'COGSModeling' && <COGSModeling />}
         {sectionID === 'Solutions' && typeID === 'FactoryDigitalTwin' && <FactoryDigitalTwin />}

         <CommonCtaArea />
         <Footer />
      </>
   );
};

export default Services;
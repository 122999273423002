import React from 'react';

const Microalgae = () => {
   return (
      <>
         {/* <section className="services__area-3 green-bg pt-100 pb-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-12">
                     <div className="section__title section__title-3 text-center">
                        <h3 className="white-color mb-30">Helping Carbon Heavy Industries Reduce Emissions Through Scalable Bio Carbon Capture & Utilization</h3>
                        <h4 className="white-color mb-0">CO2 is one of the main greenhouse gases that contributes to global warming and climate change.</h4>
                     </div>
                  </div>
               </div>
            </div>
         </section> */}
         <section className="services__area-3 green-bg pt-100 pb-100">
            <div className="container-fluid">
               <div className="row align-items-center">
                  <div className="col-xl-5">
                     <div className="section__title section__title">
                        <h2 className="white-color mb-30">What if we could turn CO2 from a problem into a solution?</h2>
                        <h4 className="white-color mb-0">We can help you convert your CO2 emissions into valuable products, such as biofuels, bioplastics, chemicals, and fertilizers in a way that is environmentally friendly, economically viable, and socially beneficial?</h4>
                     </div>
                  </div>
                  <div className="col-xl-7">
                     <div className="m-img r-img text-lg-end mt-lg-0 mt-30">
                        <img src="/assets/img/microalgae-img1.png" alt="" />
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <section className="services__area-3 green-bg pt-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-12">
                     <div className="section__title section__title text-center">
                        <h3 className="white-color mb-20">This is not a fantasy. This is a reality.</h3>
                        <h2 className="white-color mb-0">This is what microalgae can do for us.</h2>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <section className="services__area-3 green-bg pt-100 pb-100">
            <div className="container-fluid">
               <div className="row align-items-center">
                  <div className="col-xl-6">
                     <div className="section__title section__title-3">
                        <h2 className="white-color mb-50">Microalgae are microscopic aquatic organisms that can perform photosynthesis, converting CO2 and water into biomass and oxygen.</h2>
                        <h4 className="white-color mb-0">They are one of the most efficient and versatile biological resources for a circular economy, because they can:</h4>
                     </div>
                  </div>
                  <div className="col-xl-6">
                     <div className="services__tab-2 green mb-30">
                        <div className="services__content-5">
                           <h2 className="white-color">01</h2>
                           <h4 className="white-color">Capture & utilize CO2 from various sources, such as Power Plants, Cement Factories, & Aquaculture Farms.</h4>
                        </div>
                     </div>
                     <div className="services__tab-2 green mb-30">
                        <div className="services__content-5">
                           <h2 className="white-color">02</h2>
                           <h4 className="white-color">Grow in various water sources, such as seawater, wastewater, and brackish water, saving freshwater resources and improving water quality.</h4>
                        </div>
                     </div>
                     <div className="services__tab-2 green">
                        <div className="services__content-5">
                           <h2 className="white-color">03</h2>
                           <h4 className="white-color">Produce a wide range of valuable products, such as biofuels, bioplastics, chemicals, and fertilizers, replacing fossil fuels and petrochemicals.</h4>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <section className="services__area-3 green-bg pt-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-12">
                     <div className="section__title section__title text-center">
                        <h2 className="white-color mb-0">In partnership with Algoliner & MeMO3</h2>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <section className="services__area-3 green-bg pt-100 pb-100">
            <div className="container">
               <div className="row align-items-center">
                  <div className="col-xl-7">
                     <div className="section__title section__title">
                        <h2 className="white-color mb-30">What sets us apart?</h2>
                        <h4 className="white-color mb-30">We are the 1st in India to provide turnkey microrelgee closed next-generation phaobioreactors.</h4>
                        <p className="white-color mb-10">We are revolutionizing micm-algae cultivation globally with our low capital mobile factory.</p>
                        <p className="white-color mb-0">We deploy next generation phaobioreactors that are closed tubular that allows continuous manufacturing & in line monitoring for ultra la e scale operations.</p>
                     </div>
                  </div>
                  <div className="col-xl-5">
                     <div className="m-img r-img text-lg-end mt-lg-0 mt-30">
                        <img src="/assets/img/microalgae-img2.png" alt="" />
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <section className="services__area-3 green-bg pt-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-12">
                     <div className="section__title section__title text-center">
                        <h3 className="white-color mb-30">We offer intelligent turnkey solutions for microalgae cultivation, monitoring & harvest</h3>
                        <h4 className="white-color mb-0">We aim to promote sustainable practices to its clients in India and abroad, and help them to implement sustainable engineering solutions that benefit both their business and the environment.</h4>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <section className="services__area-3 green-bg pt-100 pb-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-4">
                     <div className="services__item green mb-30 mb-lg-0">
                        <div className="services__img m-img r-img mb-30">
                           <img src="/assets/img/microalgae-img3.png" alt="" />
                        </div>
                        <div className="services__content">
                           <h3>R&D</h3>
                           <p>Collaboration with research institutes & partners in Switzerland & Germany to develop micro-algae prototypes with our patented membrane technology.</p>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-4">
                     <div className="services__item green mb-30 mb-lg-0">
                        <div className="services__img m-img r-img mb-30">
                           <img src="/assets/img/microalgae-img4.png" alt="" />
                        </div>
                        <div className="services__content">
                           <h3>Pilot Operations</h3>
                           <p>Our technology is the leader in the industry, with experience in scaling-up prototypes of our experimental unit to provide the most optimal micro-algae solution.</p>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-4">
                     <div className="services__item green">
                        <div className="services__img m-img r-img mb-30">
                           <img src="/assets/img/microalgae-img5.png" alt="" />
                        </div>
                        <div className="services__content">
                           <h3>Large Seale Turnkey Solutions</h3>
                           <p>Turnkey solutions with proprietary factory in a box set up unit* for large-scale commissioning with patented equipment installation.</p>
                           <p className="d-none d-lg-block"><br /></p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <section className="services__area-3 green-bg pt-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-12">
                     <div className="section__title section__title text-center">
                        <h3 className="white-color mb-0">As compared to other existing micro-algae technology providers worldwide, our technology:</h3>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <section className="services__area-3 green-bg pt-100 pb-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-12">
                     <div className="services">
                        <div className="services__item text-center green">
                           <div className="services__content">
                              <h4>30% higher yield as compared to other closed photobioreactors</h4>
                           </div>
                        </div>
                        <div className="services__item text-center green">
                           <div className="services__content">
                              <h4>More precise control and monitoring with state of the art sensors</h4>
                           </div>
                        </div>
                        <div className="services__item text-center green">
                           <div className="services__content">
                              <h4>Higher automation level possible</h4>
                           </div>
                        </div>
                        <div className="services__item text-center green">
                           <div className="services__content">
                              <h4>Low water consumption due to closed system</h4>
                           </div>
                        </div>
                        <div className="services__item text-center green">
                           <div className="services__content">
                              <h4>Proprietary Mem03 membranes for Oxygen removal for enhanced culturing</h4>
                           </div>
                        </div>
                        <div className="services__item text-center green">
                           <div className="services__content">
                              <h4>Automated CIP with our smart pig system</h4>
                           </div>
                        </div>
                        <div className="services__item text-center green">
                           <div className="services__content">
                              <h4>Micro-algae buyback</h4>
                           </div>
                        </div>
                        <div className="services__item text-center green">
                           <div className="services__content">
                              <h4>All reactors can be equipped with integrated illumination</h4>
                           </div>
                        </div>
                        <div className="services__item text-center green">
                           <div className="services__content">
                              <h4>Patented Membrane Tech for highly efficient filtered CO2 capture</h4>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default Microalgae;
export const contactCompany = "IIoT Innovation";
export const contactAddress = "IIOT Innovation Pvt Ltd <br /> C/O Nemi Chand Jain, <br /> 2nd Floor Vinayaka Tower, <br /> Hazaribag, 825301 - Jharkhand, India";
export const contactPhone = "+91-8102283438";
export const contactWhatsapp = "https://api.whatsapp.com/send?phone=918102283438&text=Hello%20I%20have%20some%20Query";
export const contactEmail = "info@iiotinnovation.com";
export const contactMapLink = "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d14875.886157411822!2d81.65828864999999!3d21.23297715!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2s!5e0!3m2!1sen!2sin!4v1706084701013!5m2!1sen!2sin";
export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;


export const LiveWwwUrl = 'https://iiot-ten.vercel.app'
export const localWwwUrl = 'http://localhost:3000'
export const forwardWwwUrl = 'https://nn50rr96-3000.inc1.devtunnels.ms'

let BaseURLFinal = LiveWwwUrl

if (window.location.href.includes(localWwwUrl)) {
    BaseURLFinal = localWwwUrl
}

if (window.location.href.includes(forwardWwwUrl)) {
    BaseURLFinal = forwardWwwUrl
}

export const WwwUrl = BaseURLFinal

// all the API URL will be start Here
const baseApiUrl = "https://demo3.sjainventures.com/iiot/index.php/";
export const UploadURL = "https://demo3.sjainventures.com/iiot/image.php?token=";
export const AdminLoginURL = `${baseApiUrl}api/loginAdmin`;
export const AdminLoginCheckURL = `${baseApiUrl}api/loginCheck`;
export const AdminListBlogsURL = `${baseApiUrl}api/listBlogs`;
export const PublicListBlogsURL = `${baseApiUrl}api/fetchBlogs`;
export const PublicBlogDataURL = `${baseApiUrl}api/fetchBlogData`;
export const AdminUpdateBlogURL = `${baseApiUrl}api/UpdateBlog`;
// all the API URL will be end Here

export function showfulldatetime(timeStamp) {
    const tmp = new Date(timeStamp * 1000);
    return tmp.toLocaleString();
}

export function getBlogURL(slug, blogID) {
    console.log(blogID);
    return `/blog/${slug}`;
}
import React from 'react';
import { Link } from 'react-router-dom';

const CommonCtaArea = () => {
   return (
      <>
         <section
            className="cta__area pt-100 pb-100"
            style={{ background: `url(/assets/img/cta-bg.jpg)`, backgroundPosition: 'center', backgroundSize: 'cover' }}
         >
            <div className="container p-relative">
               <div className="row">
                  <div className="col-xl-10 offset-xl-1">
                     <div className="cta__content text-center">
                        {/* <span>Connect with Us - We're Just a Click Away!</span> */}
                        {/* <h1>Do you have any question? Feel free to contact us</h1> */}
                        <h1>Connect with us!</h1>

                        <div className="cta__btn">
                           <Link to="/contact" className="z-btn z-btn-white m-0">Contact Us</Link>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default CommonCtaArea;
import Slider from "react-slick";
import SingleBrandItem from "../../../components/SingleBrandItem/SingleBrandItem";

const HomeBrands = () => {
   // slick setting
   const settings = {
      autoplay: true,
      autoplaySpeed: 3000,
      dots: false,
      arrows: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
         {
            breakpoint: 1024,
            settings: {
               slidesToShow: 3,
               slidesToScroll: 1,
               infinite: true,
            }
         },
         {
            breakpoint: 767,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 1,
            }
         },
         {
            breakpoint: 576,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
            }
         },
      ]

   };
   return (
      <>
         <section className="brand__area blue-bg pt-100 pb-100">
            <div className="container-fluid">
               <div className="row align-items-center">
                  <div className="col-xl-4">
                     <div className="section__title text-center mb-50 mb-lg-0">
                        <h2 className="white-color mb-0">Our Partners</h2>
                     </div>
                  </div>
                  <div className="col-xl-8">
                     <Slider className='brand-active' {...settings}>
                        <SingleBrandItem img_1="1" />
                        <SingleBrandItem img_1="2" />
                        <SingleBrandItem img_1="3" />
                        <SingleBrandItem img_1="4" />
                        <SingleBrandItem img_1="5" />
                        <SingleBrandItem img_1="6" />
                     </Slider>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeBrands;
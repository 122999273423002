import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
// import AboutPage from '../About/AboutPage/AboutPage';
import HomeOneHeader from '../Home/HomeOneHeader/HomeOneHeader';
import CommonCtaArea from '../../components/CommonCtaArea/CommonCtaArea';

const Career = () => {
    return (
        <>
            <PageHelmet pageTitle="Career" />
            <HomeOneHeader />
            <CommonPageHeader title="Career" subtitle="Career" />
            {/* <AboutPage /> */}
            <CommonCtaArea />
            <Footer />
        </>
    );
};

export default Career;
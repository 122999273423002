import React from 'react';
import SingleService from '../../../../components/SingleService/SingleService';
import HomeThreeSecondSingleService from '../../../../components/HomeThreeSecondSingleService/HomeThreeSecondSingleService';
import { SiKnowledgebase } from 'react-icons/si';
import { BsTools, BsHeart, BsBag } from 'react-icons/bs';

const HomeServices = () => {
   return (
      <>
         <section className="services__area pt-115 pb-80">
            <div className="container">
               <div className="row">
                  <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
                     <div className="section__title section__title-3 text-center mb-90 wow fadeInUp" data-wow-delay=".2s">
                        <span>Our Services</span>
                        <h2>Provide awesome customer service with our tools.</h2>
                     </div>
                  </div>
               </div>
               <div className="row">

                  <SingleService icon="1" title="Expense reporting" />
                  <SingleService icon="2" title="Tax Advisory" />
                  <SingleService icon="3" title="Consulting" />
                  <SingleService icon="4" title="Audit & Assurance" />
                  <SingleService icon="5" title="Finance Analytics" />
                  <SingleService icon="6" title="Cord Processing" />
                  <SingleService icon="7" title="Development" />
                  <SingleService icon="8" title="Financial audit" />

               </div>
            </div>
         </section>

         <section className="capabilities__area p-relative black-bg-2 pt-180 pb-155 fix">
            <div className="capabilities__thumb p-absolute" style={{
               background: `url(/assets/img/capabilities/capabilities-img.jpg)`
               , backgroundPosition: 'center', backgroundSize: 'cover'
            }}></div>
            <div className="capabilities__shape p-absolute wow fadeInLeft" >
               <img src="/assets/img/capabilities/capabilities-shape.png" alt="shape" />
            </div>
            <div className="container">
               <div className="row">
                  <div className="col-xl-5 col-lg-6">
                     <div className="capabilities__content wow fadeInUp" data-wow-delay=".4s">
                        <div className="section__title section__title-2 mb-60">
                           <span className="white-color">Capabilities</span>
                           <h2 className="white-color">Strategy-led design in every detail</h2>
                        </div>
                        <div className="capabilities__list">
                           <ol>
                              <li >Analysis & Idea</li>
                              <li >Design & Development</li>
                              <li >Testing & Launch</li>
                           </ol>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <section className="services__area-3 pt-115 pb-160">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6">
                     <div className="section-title section__title-3 mb-70">
                        <h2>We Help <br /> Clients Make Profit</h2>
                        <p>We help you weather today's uncertainty through our best team.</p>
                     </div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-xl-12">
                     <div className="services__nav wow fadeInUp" data-wow-delay=".4s">
                        <ul className="nav nav-pills " id="services-tab" role="tablist">
                           <li className="nav-item mb-45">
                              <a className="nav-link active" id="share-tab" data-bs-toggle="pill" href="#share" role="tab" aria-controls="share" aria-selected="true">
                                 <i> <SiKnowledgebase /> </i>Share Knowledge
                              </a>
                           </li>
                           <li className="nav-item mb-30">
                              <a className="nav-link" id="customer-tab" data-bs-toggle="pill" href="#customer" role="tab" aria-controls="customer" aria-selected="true">
                                 <i ><BsTools /></i> Customer Service
                              </a>
                           </li>
                           <li className="nav-item mb-30">
                              <a className="nav-link" id="social-tab" data-bs-toggle="pill" href="#social" role="tab" aria-controls="social" aria-selected="true">
                                 <i ><BsHeart /></i> Social Media
                              </a>
                           </li>
                           <li className="nav-item mb-30">
                              <a className="nav-link" id="financial-tab" data-bs-toggle="pill" href="#financial" role="tab" aria-controls="financial" aria-selected="true">
                                 <i ><BsBag /></i> Financial
                              </a>
                           </li>
                        </ul>
                     </div>
                  </div>
                  <div className="col-xl-12">
                     <div className="services__tab grey-bg-18">
                        <div className="tab-content" id="services-content">
                           <div className="tab-pane fade show active" id="share" role="tabpanel" aria-labelledby="share-tab">
                              <HomeThreeSecondSingleService title="Share Knowledge" />
                           </div>
                           <div className="tab-pane fade" id="customer" role="tabpanel" aria-labelledby="customer-tab">
                              <HomeThreeSecondSingleService title="Customer Service" />
                           </div>
                           <div className="tab-pane fade" id="social" role="tabpanel" aria-labelledby="social-tab">
                              <HomeThreeSecondSingleService title="Social Media" />
                           </div>
                           <div className="tab-pane fade" id="financial" role="tabpanel" aria-labelledby="financial-tab">
                              <HomeThreeSecondSingleService title="Financial" />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeServices;
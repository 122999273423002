import React, { useState, useEffect } from 'react';
import { AdminListBlogsURL, AdminUpdateBlogURL, UploadURL, showfulldatetime } from '../../config';
import axios from 'axios';
import Editor from './Editor';

const ListBlogs = ({ SetIsLogin }) => {
    const [BlogForm, SetBlogForm] = useState(false);
    const [Single, SetSingle] = useState({ newImage: 0 });
    const [Blogs, SetBlogs] = useState([]);
    const [BlogCategories, SetBlogCategories] = useState([]);
    function setPageContent(content) {
        SetSingle({ ...Single, content });
    }

    const FetchBlogs = async () => {
        // Handle login logic here
        try {
            // Make API call using Axios
            const headers = {
                'loggedToken': `Basic ${localStorage.getItem('adminToken')}`,
                'Content-Type': 'application/json', // Adjust content type based on your API requirements
            };
            const response = await axios.post(AdminListBlogsURL, { token: localStorage.getItem('adminToken') }, { headers });
            // Handle successful login (use the response data as needed)
            if (Number(response.data.status) === 1 && response.data.blogs && response.data.blogs.length > 0 ) {
                SetBlogs(response.data.blogs);
            }
            if (Number(response.data.status) === 1 && response.data.blogCategories && response.data.blogCategories.length > 0 ) {
                SetBlogCategories(response.data.blogCategories);
            }
            if (Number(response.data.login) !== 1 ) {
                SetIsLogin(false);
            }
          } catch (error) {
            // Handle login failure
            SetIsLogin(false);
            console.error('Login error:', error.response.data);
          }
    };

    const UpdateBlog = async (token) => {
        // Handle login logic here
        try {
            // Make API call using Axios
            const headers = {
                'loggedToken': `Basic ${token}`,
                'Content-Type': 'application/json', // Adjust content type based on your API requirements
            };
            const response = await axios.post(AdminUpdateBlogURL, { ...Single, token }, { headers });
            FetchBlogs();
            SetBlogForm(false);
            // Handle successful login (use the response data as needed)
            if (Number(response.data.login) !== 1 ) {
                SetIsLogin(false);
            }
          } catch (error) {
            // Handle login failure
            SetIsLogin(false);
            console.error('Login error:', error.response.data);
          }
    };

    function handleFileChange(evt) {
        const file = evt.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onloadend = () => {
            SetSingle({ ...Single, image: reader.result, newImage: 1 });
          };
          reader.readAsDataURL(file);
        }
    }

    useEffect(() => {
        FetchBlogs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

   return (
      <>
         <section className="about__area pb-4 pt-4">
            <div className="container">
               <div className="row">
                    {BlogForm ? (
                        <form
                            method="POST"
                            className="col-xl-12 col-lg-12"
                            onSubmit={(e) => {
                                e.preventDefault();
                                UpdateBlog(localStorage.getItem('adminToken'));
                            }}
                        >
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="form-group">
                                        <label>Title</label>
                                        <input
                                            name="title"
                                            required
                                            type="text"
                                            className="form-control"
                                            maxLength={160}
                                            value={Single.title}
                                            onChange={(e) => {
                                                SetSingle({ ...Single, [e.target.name]: e.target.value });
                                            }}
                                        />
                                    </div>
                                    
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group mt-3">
                                                <label>Category</label>
                                                <select
                                                    required
                                                    className="form-control"
                                                    name="category"
                                                    value={Single.category}
                                                    onChange={(e) => {
                                                        SetSingle({ ...Single, [e.target.name]: e.target.value });
                                                    }}
                                                >
                                                    <option value="">Select Category</option>
                                                    {BlogCategories.map((s) => (
                                                        <option value={s.blogCatID}>{s.title}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mt-3">
                                                <label>Status</label>
                                                <select
                                                    required
                                                    className="form-control"
                                                    name="status"
                                                    value={Single.status}
                                                    onChange={(e) => {
                                                        SetSingle({ ...Single, [e.target.name]: e.target.value });
                                                    }}
                                                >
                                                    <option value="">Select Status</option>
                                                    <option value="0">Draft</option>
                                                    <option value="1">Publish</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group mt-3">
                                        <label>Tag (Comma Seperated)</label>
                                        <input
                                            required
                                            type="text"
                                            className="form-control"
                                            maxLength={160}
                                            name="tags"
                                            value={Single.tags}
                                            onChange={(e) => {
                                                SetSingle({ ...Single, [e.target.name]: e.target.value });
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Image</label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            onChange={(e) => {
                                                handleFileChange(e);
                                            }}
                                            accept='image/*'
                                        />
                                        <img
                                            src={Single.newImage !== 1 ? `${UploadURL}${Single.image}` : Single.image}
                                            alt={Single.title}
                                            className='img-responsive'
                                            style={{ maxHeight: '155px', objectFit: 'contain' }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12 mt-3 mb-3">
                                    <Editor id={Single.blogID} setPageContent={setPageContent} PageContent={Single.content} />
                                </div>
                                <div className="col-md-12 text-left">
                                    <button
                                        type="button"
                                        onClick={(e) => {
                                            SetBlogForm(false);
                                        }}
                                        className="btn btn-outline-danger me-2"
                                    >
                                        Cancel
                                    </button>
                                    <button className="btn btn-success" type="submit">Save</button>
                                </div>
                            </div>
                        </form>
                    ) : (
                        <>
                            {Blogs.length > 0 ? (
                                <>
                                    <div className="col-md-12 text-right">
                                        <a
                                            href="/"
                                            style={{ float: 'right' }}
                                            className='btn btn-outline-success'
                                            onClick={(e) => {
                                                e.preventDefault(0);
                                                SetSingle({ blogID: 0, content: '', status: 1, newImage: 1, image: 'https://placehold.co/500x190?text=Choose%20Image' });
                                                SetBlogForm(true);
                                            }}
                                        >
                                            <i className="fa fa-plus"></i>
                                        </a>
                                        <div style={{ clear: 'both' }}></div>
                                    </div>
                                    <table className="table table-hover mt-2 table-bordered">
                                        <thead className="bg-dark text-white">
                                            <tr>
                                                <td>#</td>
                                                <td>Image</td>
                                                <td>Title</td>
                                                <td>Status</td>
                                                <td>Action</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Blogs.map((s) => (
                                                <tr title={`Created on ${showfulldatetime(s.createdAt)}`} key={s.blogID}>
                                                    <td>{s.blogID}</td>
                                                    <td>
                                                        <img src={`${UploadURL}${s.image}`} alt="" style={{ maxWidth: '150px' }} />
                                                    </td>
                                                    <td>{s.title}</td>
                                                    <td>
                                                        {Number(s.status) === 1 ? (
                                                            <button type="button" className="btn btn-sm btn-success">Published</button>
                                                        ) : (
                                                            <button type="button" className="btn btn-sm btn-warning">Draft</button>
                                                        )}
                                                    </td>
                                                    <td title={`Updated at ${showfulldatetime(s.updatedAt)}`}>
                                                        <a
                                                            href="/"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                SetSingle(s);
                                                                SetBlogForm(true);
                                                            }}
                                                        >
                                                            <i className="fa fa-edit"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </>
                            ) : (
                                <div className="alert alert-warning">
                                    There is no Blogs.
                                </div>
                            )}
                        </>
                    )}
               </div>
            </div>
         </section>
      </>
   );
};

export default ListBlogs;
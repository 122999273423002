import React from 'react';

const HomeVideo = () => {
    return (
        <>
            <section className="services__area black-bg pt-100 pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-xl-6">
                            <div className="d-flex">
                                <video autoPlay muted loop id="video" style={{ width: '100%' }}>
                                    <source src="/assets/video/Tulip.mp4" type="video/mp4" />
                                </video>
                            </div>
                        </div>
                        <div className="col-xl-5 offset-xl-1">
                            <div className="about__content">
                                <div className="section__title">
                                    <h3 className="white-color mb-0">"Streamline your discrete manufacturing with Tulip's intuitive no-code App Editor, designed to enhance operational efficiency and innovation on the shop floor."</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default HomeVideo;
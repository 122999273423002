import React from 'react';
import { Link } from 'react-router-dom';

const FrontlineAutomation = () => {
    return (
        <>
            <section
                className="cta__area pt-100 pb-100"
                style={{ background: `url(/assets/img/cta-bg.jpg)`, backgroundPosition: 'center', backgroundSize: 'cover' }}
            >
                <div className="container p-relative">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="cta__content text-center">
                                <h1 className="mb-4">The World Runs on Apps.<br />Factories run on Tulip.</h1>
                                <span className="mt-2 mb-4">Transform your production floor with connected digital solutions that empower humans - interactive interfaces, unparalleled flexibility, adaptability without coding, AI augmentation and real-time contextualized data.</span>
                                <ul>
                                    <li>No-Code</li>
                                    <li>Cloud-Native</li>
                                    <li>Edge Connectivity</li>
                                    <li>Enterprise-Ready</li>
                                </ul>
                                <div className="cta__btn mt-50">
                                    <Link to="/contact" className="z-btn z-btn-white m-0">Request a Demo</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="services__area-3 black-bg pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-3 text-center">
                                <h3 className="white-color mb-30">We're introducing Indian  to a new, smarter way to run their operations.</h3>
                                <h4 className="white-color mb-0">Here's how our services can revolutionize your operations:</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <section className="services__area-3 black-bg pt-100 pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-xl-4">
                            <div className="section__title section__title">
                                <h2 className="white-color mb-30">A new, smarter way to run your operations.</h2>
                                <p className="white-color">Our Frontline Automation Platform 'Tulip' is helping companies around the world equip their workforce with the skills, tools, and insights they need to perform at their best.</p>
                                <p className="white-color mb-0">Digitally transform workflows, guide employees, and gain real-time visibility into the performance, productivity, and engagement of your workforce -<b className='fs-6'> All in days</b>.</p>
                            </div>
                        </div>
                        <div className="col-xl-1"></div>
                        <div className="col-xl-7">
                            <div className="m-img mt-lg-0 mt-30">
                                <img src="/assets/img/front-auto-img1.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services__area-3 black-bg pt-100 pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-xl-7 order-2 order-lg-1">
                            <div className="m-img mt-lg-0 mt-30">
                                <img src="/assets/img/front-auto-img2.png" alt="" />
                            </div>
                        </div>
                        <div className="col-xl-4 order-1 order-lg-2">
                            <div className="section__title section__title ms-xl-5">
                                <h2 className="white-color mb-30">Connected apps power transformation.</h2>
                                <p className="white-color">Empower the managers and leaders closest to the workforce to create intuitive, integrated apps with a no-code, visual interface.</p>
                                <p className="white-color mb-0">Expand to interconnected systems of apps that integrate and optimize your full workforce processes.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="cta__area page__title-blue pt-100 pb-100"
                style={{ background: `url(/assets/img/cta-bg.jpg)`, backgroundPosition: 'center', backgroundSize: 'cover' }}
            >
                <div className="container p-relative">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="cta__content text-center">
                                <h1 className="mb-0">Capabilities</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services__area-3 black-bg pt-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-xl-5 offset-xl-1">
                            <div className="section__title section__title">
                                <h2 className="white-color mb-30">App Editor</h2>
                                <p className="white-color mb-0">Create and edit apps with no-code editing</p>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="m-img mt-lg-0 mt-30">
                                <img src="/assets/img/front-auto-img3.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services__area-3 black-bg pt-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-xl-6 order-2 order-lg-1">
                            <div className="m-img mt-lg-0 mt-30">
                                <img src="/assets/img/front-auto-img4.png" alt="" />
                            </div>
                        </div>
                        <div className="col-xl-5 offset-xl-1 order-1 order-lg-2">
                            <div className="section__title section__title">
                                <h2 className="white-color mb-30">Data & Analytics</h2>
                                <p className="white-color mb-0">Visualize real-time data, create dashboards</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services__area-3 black-bg pt-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-xl-5 offset-xl-1">
                            <div className="section__title section__title">
                                <h2 className="white-color mb-30">Computer Vision</h2>
                                <p className="white-color mb-0">Guide, detect, improve operations with cameras</p>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="m-img mt-lg-0 mt-30">
                                <img src="/assets/img/front-auto-img5.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services__area-3 black-bg pt-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-xl-6 order-2 order-lg-1">
                            <div className="m-img mt-lg-0 mt-30">
                                <img src="/assets/img/front-auto-img6.png" alt="" />
                            </div>
                        </div>
                        <div className="col-xl-5 offset-xl-1 order-1 order-lg-2">
                            <div className="section__title section__title">
                                <h2 className="white-color mb-30">Automations & Integrations</h2>
                                <p className="white-color mb-0">Run logic in the background of your operations. Connect to ERPs, collaboration tools, etc.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services__area-3 black-bg pt-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-xl-5 offset-xl-1">
                            <div className="section__title section__title">
                                <h2 className="white-color mb-30">Frontline Copilot<sup>TM</sup></h2>
                                <p className="white-color mb-0">Upcoming AI tools for answering questions and exploring data</p>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="m-img mt-lg-0 mt-30">
                                <img src="/assets/img/front-auto-img8.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="services__area-3 black-bg pt-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-xl-5 offset-xl-1">
                            <div className="section__title section__title">
                                <h2 className="white-color mb-30">Governance & Change Management</h2>
                                <p className="white-color mb-0">Equip citizen developers while enforcing procedures</p>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="m-img mt-lg-0 mt-30">
                                <img src="/assets/img/front-auto-img9.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <section className="services__area-3 black-bg pt-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-xl-6 order-2 order-lg-1">
                            <div className="m-img mt-lg-0 mt-30">
                                <img src="/assets/img/front-auto-img10.png" alt="" />
                            </div>
                        </div>
                        <div className="col-xl-5 offset-xl-1 order-1 order-lg-2">
                            <div className="section__title section__title">
                                <h2 className="white-color mb-30">Integrated Validation</h2>
                                <p className="white-color mb-0">Reduce validation efforts with a qualified platform</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services__area-3 black-bg pt-100 pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-xl-5 offset-xl-1">
                            <div className="section__title section__title">
                                <h2 className="white-color mb-30">GxP-ready</h2>
                                <p className="white-color mb-0">Features to help with regulation compliance. Tulip provides Quality Management Systems.</p>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="m-img mt-lg-0 mt-30">
                                <img src="/assets/img/front-auto-img11.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="services__area-3 black-bg pt-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-xl-6 order-2 order-lg-1">
                            <div className="m-img mt-lg-0 mt-30">
                                <img src="/assets/img/front-auto-img12.png" alt="" />
                            </div>
                        </div>
                        <div className="col-xl-5 offset-xl-1 order-1 order-lg-2">
                            <div className="section__title section__title">
                                <h2 className="white-color mb-30">QMS</h2>
                                <p className="white-color mb-0">Tulip provides Quality Management Systems</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <section className="services__area-3 black-bg pt-100 pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-xl-5 offset-xl-1">
                            <div className="section__title section__title">
                                <h2 className="white-color mb-30">Workspaces</h2>
                                <p className="white-color mb-0">Use Tulip across sites around the world</p>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="m-img mt-lg-0 mt-30">
                                <img src="/assets/img/front-auto-img13.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
        </>
    );
};

export default FrontlineAutomation;
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import PageHelmet from '../../components/shared/PageHelmet';
// import BlogFooter from '../Blogs/BlogFooter/BlogFooter';
import BlogFooter from '../../components/shared/Footer';
import HomeOneHeader from '../Home/HomeOneHeader/HomeOneHeader';
import BlogDetailsArea from './BlogDetailsArea/BlogDetailsArea';
import axios from 'axios';
import { PublicBlogDataURL } from '../../config';

const BlogDetails = () => {
   const { slug } = useParams();
   const [Loaded, SetLoaded] = useState(false);
   const [Blog, SetBlog] = useState({ title: '' });
   const FetchBlog = async (slug) => {
      // Handle login logic here
      SetBlog({ title: 'Loading...' });
      SetLoaded(false);
      try {
          // Make API call using Axios
          const headers = { 'Content-Type': 'application/json' };
          const response = await axios.post(PublicBlogDataURL, { slug }, { headers });
          // Handle successful login (use the response data as needed)
          if (Number(response.data.status) === 1 && response.data.blog && response.data.blog.length > 0 ) {
            SetBlog(response.data.blog[0]);
            SetLoaded(true);
          } else {
            window.location.href= '/blog/';
          }
        } catch (error) {
          // Handle login failure
          SetLoaded(true);
          console.error('Login error:', error.response.data);
        }
  };

  useEffect(() => {
      FetchBlog(slug);
   }, [slug]);
   return (
      <>
         <PageHelmet pageTitle={Blog.title} />
         <HomeOneHeader/>
         <CommonPageHeader title={Blog.title} subtitle="Blogs" />
         <BlogDetailsArea Blog={Blog} Loaded={Loaded} />
         <BlogFooter/>
      </>
   );
};

export default BlogDetails;
import React from 'react';
import { Link } from 'react-router-dom';
import { CgArrowLongRight } from 'react-icons/cg';

const HomeServices = () => {
   return (
      <>
         <section className="services__area blue-bg pt-100 pb-100">
            <div className="container-fluid">
               <div className="row">
                  <div className="col-xl-12">
                     <div className="section__title section__title text-center mb-50">
                        <span className="white-color">Smarter Way to Achieve your Goals</span>
                        <h2 className="white-color">Net Zero Transition</h2>
                     </div>
                  </div>
               </div>
               <div className="row">
                  {/* <div className="col-xl-3">
                     <div className="services__item mb-30">
                        <div className="services__img m-img r-img mb-30">
                           <img src="/assets/img/home-img2.jpg" alt="" />
                        </div>
                        <div className="services__content">
                           <h3>Green Hydrogen Transition</h3>
                           <p>We help you harness the power of green hydrogen, a clean and versatile energy carrier that can be used for various applications.</p>
                           <p className="d-none d-lg-block"><br /></p>
                        </div>
                     </div>
                  </div> */}
                  <div className="col-xl-3">
                     <div className="services__item">
                        <div className="services__icon mb-30">
                           <img src="/assets/img/home-icon1.png" alt="" />
                        </div>
                        <div className="services__content">
                           <h3>Green Hydrogen Transition Analysis</h3>
                           <p>We help you analyze the power of green hydrogen, a clean and versatile energy carrier that can be used for various applications.</p>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-3">
                     <div className="services__item">
                        <div className="services__icon mb-30">
                           <img src="/assets/img/home-icon2.png" alt="" />
                        </div>
                        <div className="services__content">
                           <h3>EV Network Modeling</h3>
                           <p>We help you electrify your transportation and mobility needs with our EV network models.</p>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-3">
                     <div className="services__item">
                        <div className="services__icon mb-30">
                           <img src="/assets/img/home-icon3.png" alt="" />
                        </div>
                        <div className="services__content">
                           <h3>Microgrid Analysis</h3>
                           <p>We help you improve your energy resilience and security with our microgrid analysis service.</p>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-3">
                     <div className="services__item">
                        <div className="services__icon mb-30">
                           <img src="/assets/img/home-icon4.png" alt="" />
                        </div>
                        <div className="services__content">
                           <h3>Ammonia Analysis & Dry Methane Reforming Yield Modeling</h3>
                           <p>We help you explore the potential of ammonia and dry methane reforming, two promising technologies that can enable low-carbon and circular economy.</p>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-xl-12">
                     <div className="text-center mt-30">
                        <Link to="/services/NetZeroTransition" className="z-btn">Learn More <i><CgArrowLongRight /></i></Link>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeServices;
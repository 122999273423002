import React from 'react';
import { Link } from 'react-router-dom';

const HomeCarbon = () => {
    return (
        <>
            <section className="about__area black-bg pt-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-xl-7">
                            <div className="about__thumb m-img r-img">
                                <Link to="/services/CarbonCapture/Microalgae"><img src="assets/img/home-carbon-img1.jpg" alt="" /></Link>
                            </div>
                        </div>
                        <div className="col-xl-5">
                            <div className="ms-lg-5">
                                <div className="about__content">
                                    <div className="section__title">
                                        <h3 className="white-color mb-0 me-lg-5">We are excited to announce our new division dedicated to Carbon Capture and Utilization as a Service (CCU-aaS).</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default HomeCarbon;
import React from 'react';
import {  FaUserClock } from 'react-icons/fa';
import { CgArrowLongRight } from 'react-icons/cg';
import { Link } from 'react-router-dom';
import { UploadURL, getBlogURL, showfulldatetime } from '../../config';

const BlogSingleItem = ({ blogData }) => {
   return (
      <>
         <div className="blog__item-2 mb-50 fix" >
            <div className={`blog__thumb-2 w-img fix`}>
               <Link to={getBlogURL(blogData.slug)}>
                  <img src={`${UploadURL}${blogData.image}`} alt={blogData.title} />
               </Link>
            </div>
            <div className="blog__content-2">
               <h3>
                  <Link to={getBlogURL(blogData.slug)}>
                     {blogData.title}
                  </Link>
               </h3>
               {/* <p>I don't want no agro say chimney pot plastered bamboozled arse Why dropped a clanger cheeky bloke, Eaton crikey morish me old mucker cuppa[...]</p> */}
               <div className="blog__btn d-sm-flex justify-content-between">
                  <div className="blog__btn">
                     <Link to={getBlogURL(blogData.slug)}>
                        Read More
                        <i > <CgArrowLongRight/> </i>
                        <i > <CgArrowLongRight /> </i>
                     </Link>
                  </div>
                  <div className="blog__comment">
                     <Link to={getBlogURL(blogData.slug)}>
                        <i><FaUserClock/></i>
                        {showfulldatetime(blogData.updatedAt)}
                     </Link>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};

export default BlogSingleItem;
import React from 'react';
import HomeOneHeader from './HomeOneHeader/HomeOneHeader';
import HomeHeroSlider from './HomeHeroArea/HomeHeroSlider';
import HomeCarbon from './HomeCarbon/HomeCarbon';
import HomeAbout from './HomeAbout/HomeAbout';
import HomeCapabilities from './HomeCapabilities/HomeCapabilities';
import HomeVideo from './HomeVideo/HomeVideo';
import HomeVideoTwo from './HomeVideoTwo/HomeVideoTwo';
import HomeServices from './HomeServices/HomeServices';
// import HomeContact from './HomeContact/HomeContact';
import HomeBrands from './HomeBrands/HomeBrands';
import CommonCtaArea from '../../components/CommonCtaArea/CommonCtaArea';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';


const Home = () => {
    return (
        <>
            <PageHelmet pageTitle="Home" />
            <HomeOneHeader />
            <HomeHeroSlider />
            <HomeCarbon />
            <HomeAbout />
            <HomeCapabilities />
            <HomeVideo />
            <HomeServices />
            <HomeVideoTwo />
            <HomeBrands />
            <CommonCtaArea />
            {/* <HomeContact /> */}
            <Footer />
        </>
    );
};

export default Home;
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollTop from "./components/ScrollTop";
import BackToTop from "./components/BackToTop";
import About from "./pages/About/About";
import Career from "./pages/Career/Career";
import AdminLogin from "./pages/Admin/Index";
import BlogDetails from "./pages/BlogDetails/BlogDetails";
import Blogs from "./pages/Blogs/Blogs";
import Contact from "./pages/Contact/Contact";
import Home from './pages/Home/Home';
import NetZeroNew from "./pages/Services/serviceDetails/NetZeroNew";
import MicroalgaeNew from "./pages/Services/serviceDetails/CarbonCapture/MicroalgaeNew";
// import Portfolio from "./pages/Portfolio/Portfolio";
// import PortfolioDetails from "./pages/PortfolioDetails/PortfolioDetails";
import Services from "./pages/Services/Services";
// import Team from "./pages/Team/Team";
// import TeamDetails from "./pages/TeamDetails/TeamDetails";
import "./App.css";

import { SpeedInsights } from "@vercel/speed-insights/react"


function App() {
  return (
    <>
      <BrowserRouter>
        <SpeedInsights />
        <ScrollTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/career" element={<Career />} />
          <Route path="/services/NetZeroTransition" element={<NetZeroNew />} />
          <Route path="/services/CarbonCapture/Microalgae" element={<MicroalgaeNew />} />
          <Route path="/services" element={<Services />} />
          <Route path="/services/:sectionID/:typeID" element={<Services />} />
          {/* <Route path="/servicesDetails" element={<ServicesDetails/>} /> */}
          {/* <Route path="/portfolio" element={<Portfolio/>} />
            <Route path="/portfolioDetails" element={<PortfolioDetails/>} /> */}
          {/* <Route path="/team" element={<Team/>} />
            <Route path="/teamDetails" element={<TeamDetails/>} /> */}
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogs/:blogCatID" element={<Blogs />} />
          <Route path="/blog/:slug" element={<BlogDetails />} />
          <Route path="/contact" element={<Contact />} />
          {/* for the login routes */}
          <Route path="/adminLogin" element={<AdminLogin />} />
          <Route path="/adminLogin/:pageID" element={<AdminLogin />} />
          <Route path="*" element={<Home />} />
          {/* for the login routes */}
        </Routes>
        <BackToTop />
      </BrowserRouter>
    </>
  );
}

export default App;

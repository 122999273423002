import React from 'react';
import { WwwUrl } from '../../config';

const About = () => {
   return (
      <center className="mt-3 mb-3">
         <img
            alt=""
            src={`${WwwUrl}/assets/img/loader.gif`}
            style={{
               maxWidth: '50px'
            }}
         />
         <p>Fetching Data...</p>
      </center>
   );
};

export default About;
import React, { useRef, useEffect } from 'react';

import Section from './section';
import { FaCheck } from 'react-icons/fa';

const HomeServices = ({ typeID, sectionID }) => {
   const SectionGreenHydrogenTransition = useRef(null);
   const SectionEVChargingStation = useRef(null);
   const SectionMicrogridAnalysis = useRef(null);
   const SectionAmmoniaAnalysis = useRef(null);
   const SectionDryMethaneReforming = useRef(null);
   useEffect(() => {
      if (typeID === 'GreenHydrogenTransition') {
         SectionGreenHydrogenTransition.current.scrollIntoView({ behavior: 'smooth' });
      }
      if (typeID === 'EVChargingStation') {
         SectionEVChargingStation.current.scrollIntoView({ behavior: 'smooth' });
      }
      if (typeID === 'MicrogridAnalysis') {
         SectionMicrogridAnalysis.current.scrollIntoView({ behavior: 'smooth' });
      }
      if (typeID === 'AmmoniaAnalysis') {
         SectionAmmoniaAnalysis.current.scrollIntoView({ behavior: 'smooth' });
      }
      if (typeID === 'DryMethaneReforming') {
         SectionDryMethaneReforming.current.scrollIntoView({ behavior: 'smooth' });
      }
   }, [typeID]);
   return (
      <>
         <section style={{ background: '#000000' }}>
            <section className="services__area-3 pt-100 pb-100">
               <div className="container">
                  <div className="row">
                     <div className="col-xl-12">
                        <div className="section__title section__title-3 text-center mb-40">
                           <h2 className="white-color">Analyze & compare energy transition strategies to manage your entire portfolio</h2>
                        </div>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-xl-8">
                        <div className="services">
                           <div className="services__item text-center">
                              <div className="services__icon mb-25">
                                 <img src="/assets/img/netzero-icon1.png" alt="" />
                              </div>
                              <div className="services__content">
                                 <h4>Clean Base Load Power (Wind, Solar, Geothermal) Microgrids</h4>
                              </div>
                           </div>
                           <div className="services__item text-center">
                              <div className="services__icon mb-25">
                                 <img src="/assets/img/netzero-icon2.png" alt="" />
                              </div>
                              <div className="services__content">
                                 <h4>Carbon Capture, Utilization & Storage</h4>
                              </div>
                           </div>
                           <div className="services__item text-center">
                              <div className="services__icon mb-25">
                                 <img src="/assets/img/netzero-icon3.png" alt="" />
                              </div>
                              <div className="services__content">
                                 <h4>Sustainable Aviation Fuel</h4>
                              </div>
                           </div>
                           <div className="services__item text-center">
                              <div className="services__icon mb-25">
                                 <img src="/assets/img/netzero-icon4.png" alt="" />
                              </div>
                              <div className="services__content">
                                 <h4>EV Charging</h4>
                              </div>
                           </div>
                           <div className="services__item text-center">
                              <div className="services__icon mb-25">
                                 <img src="/assets/img/netzero-icon5.png" alt="" />
                              </div>
                              <div className="services__content">
                                 <h4>Green Hydrogen / Ammonia / Methanol Analysis</h4>
                              </div>
                           </div>
                           <div className="services__item text-center">
                              <div className="services__icon mb-25">
                                 <img src="/assets/img/netzero-icon6.png" alt="" />
                              </div>
                              <div className="services__content">
                                 <h4>Circular Economy</h4>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-4">
                        <div className="services__tab-2">
                           <div className="services__content-4">
                              <h3 className="white-color mb-25">Example Use Cases</h3>
                              <ul>
                                 <li>Computations of emission & abatement across multiple energy sources.</li>
                                 <li>Energy yield and cost comparisons across multiple locations.</li>
                                 <li>Cross asset analysis for clean base load power.</li>
                                 <li>Reducing non-productive time for low carbon fossil fuel production.</li>
                                 <li>Reliable and sustainable supply of green hydrogen and derivatives.</li>
                              </ul>
                              <p className="d-none d-lg-block" style={{ height: '60px' }}>&nbsp;</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="capabilities__area p-relative black-bg pt-100 pb-100 fix">
               <div
                  className="capabilities__thumb p-absolute"
                  style={{ background: `url(/assets/img/netzero-bg1.jpg)`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
               </div>
               <div className="container">
                  <div className="row">
                     <div className="col-xl-6">
                        <div className="capabilities__content">
                           <div className="section__title section__title-3 mb-40">
                              <span className="white-color">Companies struggle with data driven insights to achieve energy transition</span>
                              <h2 className="white-color">What we offer is a</h2>
                           </div>
                           <div className="capabilities__list">
                              <ol>
                                 <li>No/Low Code</li>
                                 <li>AI Based Planning Tool that Automatically</li>
                                 <li>Simulates Validated Data to Bring</li>
                                 <li>Ease & Precision in Strategic Decision</li>
                                 <li>Accelerate Energy Transition</li>
                              </ol>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="services__area-3 pt-100">
               <div className="container">
                  <div className="row">
                     <div className="col-xl-12">
                        <div className="section__title section__title-4 text-center mb-40">
                           <h2 className="white-color">We can instantly simulate transition needs with its no code platform</h2>
                        </div>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-xl-6">
                        <div className="services flex-column">
                           <div className="services__item d-flex align-items-lg-center flex-lg-row flex-column">
                              <div className="services__icon me-lg-4 mb-lg-0 mb-4">
                                 <img src="/assets/img/netzero-icon7.png" alt="" />
                              </div>
                              <div className="services__content-4">
                                 <h4 className="text-success">SAAS PLATFORM</h4>
                                 <ul>
                                    <li className="mb-5">Integrates data, process, & analytics</li>
                                    <li className="mb-0">Creates single source of truth</li>
                                 </ul>
                              </div>
                           </div>
                           <div className="services__item d-flex align-items-lg-center flex-lg-row flex-column">
                              <div className="services__icon me-lg-4 mb-lg-0 mb-4">
                                 <img src="/assets/img/netzero-icon8.png" alt="" />
                              </div>
                              <div className="services__content-4">
                                 <h4 className="text-success">COLLABORATION</h4>
                                 <ul>
                                    <li className="mb-5">Reduces process timelines</li>
                                    <li className="mb-0">Advances execution of NetZero plans</li>
                                 </ul>
                              </div>
                           </div>
                           <div className="services__item d-flex align-items-lg-center flex-lg-row flex-column">
                              <div className="services__icon me-lg-4 mb-lg-0 mb-4">
                                 <img src="/assets/img/netzero-icon9.png" alt="" />
                              </div>
                              <div className="services__content-4">
                                 <h4 className="text-success">VIRTUAL SMES</h4>
                                 <ul>
                                    <li className="mb-5">Embeds expertise in models to create 24x7 virtual SMEs</li>
                                    <li className="mb-0">Eliminates need to build huge departments to do</li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-6">
                        <div className="services__tab-2">
                           <div className="services__content-4">
                              <ul>
                                 <li><b>Connects plans and stakeholders across value chain</b></li>
                                 <li><b>Analysis in hours vs months</b></li>
                              </ul>
                              <div className="m-img mt-30">
                                 <img src="/assets/img/netzero-img5.jpg" alt="" className="m-0" />
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="about__area pt-100">
               <div className="container">
                  <div className="row">
                     <div className="col-xl-12">
                        <div className="section__title section__title-3 mb-40">
                           <h2 className="white-color">A new, smarter way to achieve your net zero goals</h2>
                        </div>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-md-12">
                        <div className="about__list">
                           <ul>
                              <li><span className="white-color"><i> <FaCheck /> </i> Our Net Zero Services are designed to help you accelerate your transition to low-carbon and sustainable operations.</span></li>
                              <li><span className="white-color"><i> <FaCheck /> </i> We partner with Dsider.app, a leading provider of software solutions for industrial automation, to offer you a comprehensive range of services that cover various aspects of net zero planning and implementation.</span></li>
                              <li><span className="white-color"><i> <FaCheck /> </i> Whether you need to reduce your carbon footprint, increase your energy efficiency, or adopt new technologies, we have the expertise and the tools to help you succeed.</span></li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <div ref={SectionGreenHydrogenTransition}>
               <Section v={0} typeID="Green Hydrogen Transition" sectionID={sectionID} />
            </div>
            <div className="container">
               <div className="row">
                  <div className="col-md-12">
                     <div className="hrBox">
                        <hr />
                     </div>
                  </div>
               </div>
            </div>
            <div ref={SectionEVChargingStation}>
               <Section v={1} typeID="EV Charging Station" sectionID={sectionID} />
            </div>
            <div className="container">
               <div className="row">
                  <div className="col-md-12">
                     <div className="hrBox">
                        <hr />
                     </div>
                  </div>
               </div>
            </div>
            <div ref={SectionMicrogridAnalysis}>
               <Section v={2} typeID="Microgrid Analysis" sectionID={sectionID} />
            </div>
            <div className="container">
               <div className="row">
                  <div className="col-md-12">
                     <div className="hrBox">
                        <hr />
                     </div>
                  </div>
               </div>
            </div>
            <div ref={SectionAmmoniaAnalysis}>
               <Section v={3} typeID="Ammonia Analysis" sectionID={sectionID} />
            </div>
            <section className="services__area-3 pt-100">
               <div className="container">
                  <div className="row">
                     <div className="col-xl-12">
                        <div className="section__title section__title-3 text-center mb-lg-5">
                           <h2 className="white-color">Why Choose Us</h2>
                        </div>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-xl-3">
                        <div className="services__tab-2">
                           <div className="services__content-4">
                              <h2 className="mb-20 white-color">01</h2>
                              <h4 className="white-color">We are a trusted and experienced company that offers a comprehensive range of net zero services to meet your needs.</h4>
                              <p className="d-none d-lg-block"><br /><br /><br /><br /></p>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-3">
                        <div className="services__tab-2">
                           <div className="services__content-4">
                              <h2 className="mb-20 white-color">02</h2>
                              <h4 className="white-color">We have a strong partnership with Dsider.app, a leading provider of software solutions for industrial automation, that enables us to leverage the benefits of Industry 4.0 and IIoT for your net zero projects.</h4>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-3">
                        <div className="services__tab-2">
                           <div className="services__content-4">
                              <h2 className="mb-20 white-color">03</h2>
                              <h4 className="white-color">We have the expertise and the tools to help you plan and execute your net zero journey, from start to finish.</h4>
                              <p className="d-none d-lg-block"><br /><br /><br /><br /><br /></p>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-3">
                        <div className="services__tab-2">
                           <div className="services__content-4">
                              <h2 className="mb-20 white-color">04</h2>
                              <h4 className="white-color">We are not just a service provider, we are your partner in net zero. Contact us today to find out how we can help you achieve your net zero goals.</h4>
                              <p className="d-none d-lg-block"><br /><br /><br /></p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="services__area-3 pt-100">
               <div className="container">
                  <div className="row">
                     <div className="col-xl-12">
                        <div className="section__title section__title-4 text-center mb-lg-5">
                           <h2 className="white-color">Net zero operations require detailed planning & has several moving parts</h2>
                        </div>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-xl-6">
                        <div className="services__tab-2">
                           <div className="services__content-4">
                              <h3 className="text-success">Current State</h3>
                              <h4 className="mb-25">Enterprise Net Zero Planning</h4>
                              <ul>
                                 <li>Nascent Knowledge & Evolving Technologies</li>
                                 <li>Non-Existent Proven Data</li>
                                 <li>Changing Regulations</li>
                                 <li>Emerging Data Sources</li>
                                 <li>Shifting Capital Market</li>
                              </ul>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-6">
                        <div className="services__tab-2">
                           <div className="services__content-4">
                              <h3 className="text-danger mb-25">But current approaches and solutions leave key questions unanswered</h3>
                              <ul>
                                 <li>Detailed Risk Analysis</li>
                                 <li>Trade-Offs</li>
                                 <li>Granular Metrics</li>
                                 <li>Flexibility</li>
                                 <li>Prioritization</li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="services__area-3 pt-100">
               <div className="container">
                  <div className="row">
                     <div className="col-xl-12">
                        <div className="section__title section__title-4 mb-50">
                           <h2 className="white-color mb-0">Dsider can instantly simulate transition needs with its no code platform</h2>
                           <span className="white-color mb-0">Address net zero priorities with ease and precision</span>
                        </div>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-xl-2">
                        <h4 className="white-color mb-0">OUTPUT</h4>
                     </div>
                     <div className="col-xl-10">
                        <div className="services__tab-2">
                           <div className="services__content-4">
                              <h3 className="mb-30 text-center">Planning For Sustainability By Design</h3>
                           </div>
                           <div className="row">
                              <div className="col-xl-3">
                                 <div className="services__item text-center mb-30 mb-lg-0">
                                    <div className="services__icon">
                                       <img src="/assets/img/netzero-icon10.png" alt="" className="mb-3" />
                                    </div>
                                    <div className="services__content">
                                       <h4>Forward Forecasting</h4>
                                    </div>
                                 </div>
                              </div>
                              <div className="col-xl-3">
                                 <div className="services__item text-center mb-30 mb-lg-0">
                                    <div className="services__icon">
                                       <img src="/assets/img/netzero-icon11.png" alt="" className="mb-3" />
                                    </div>
                                    <div className="services__content">
                                       <h4>Standardized Metrics</h4>
                                    </div>
                                 </div>
                              </div>
                              <div className="col-xl-3">
                                 <div className="services__item text-center mb-30 mb-lg-0">
                                    <div className="services__icon">
                                       <img src="/assets/img/netzero-icon12.png" alt="" className="mb-3" />
                                    </div>
                                    <div className="services__content">
                                       <h4>Scenario Analysis</h4>
                                    </div>
                                 </div>
                              </div>
                              <div className="col-xl-3">
                                 <div className="services__item text-center">
                                    <div className="services__icon">
                                       <img src="/assets/img/netzero-icon13.png" alt="" className="mb-3" />
                                    </div>
                                    <div className="services__content">
                                       <h4>Risk and Trade-Offs</h4>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  {/* <div className="row mb-50">
                     <div className="col-xl-2">
                        <h4 className="white-color mb-0">MODEL</h4>
                     </div>
                     <div className="col-xl-10">
                        <div className="services__tab-2">
                           <div className="services__content-4">
                              <h3 className="mb-lg-4 text-center">No Code Simulation Platform</h3>
                           </div>
                           <div className="row">
                              <div className="col-xl-3">
                                 <div className="services__tab-2">
                                    <div className="services__content-4">
                                       <h4 className="mb-15">Simulated Yield</h4>
                                       <ul>
                                          <li className="mb-5"><b>Energy Balance</b></li>
                                          <li className="mb-5"><b>Material Balance</b></li>
                                          <li className="mb-0"><b>Resource Balance</b></li>
                                       </ul>
                                    </div>
                                 </div>
                              </div>
                              <div className="col-xl-4">
                                 <div className="services__tab-2">
                                    <div className="services__content-4">
                                       <h4 className="mb-15">Simulated Economics</h4>
                                       <ul>
                                          <li className="mb-5"><b>NPI/IRR Metrics</b></li>
                                          <li className="mb-5"><b>Cash Flow Projection</b></li>
                                          <li className="mb-0"><b>Tax Credit Impact & Subsidy</b></li>
                                       </ul>
                                    </div>
                                 </div>
                              </div>
                              <div className="col-xl-5">
                                 <div className="services__tab-2">
                                    <div className="services__content-4">
                                       <h4 className="mb-15">Simulated Carbon Abatement</h4>
                                       <ul>
                                          <li className="mb-5"><b>Carbon Intensity of Energy</b></li>
                                          <li className="mb-5"><b>Process & Component Carbon Intensity</b></li>
                                          <li className="mb-0"><b>Aggregate Scope 3 Emissions</b></li>
                                       </ul>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-xl-2">
                        <h4 className="white-color mb-0">INPUT</h4>
                     </div>
                     <div className="col-xl-10">
                        <div className="services__tab-2">
                           <div className="services__content-4">
                              <h3 className="mb-0 text-center">Connected Data from Across Value Chain</h3>
                           </div>
                        </div>
                     </div>
                  </div> */}
               </div>
            </section>
            <section className="services__area-3 pt-100">
               <div className="container">
                  <div className="row">
                     <div className="col-xl-12">
                        <div className="section__title section__title-4 text-center mb-40">
                           <h2 className="white-color">References of previous projects with Dsider planning tool</h2>
                        </div>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-xl-12">
                        <div className="services">
                           <div className="services__item d-flex flex-lg-row flex-column">
                              <div className="services__icon me-lg-3 mb-lg-0 mb-4">
                                 <img src="/assets/img/netzero-project1.png" alt="" />
                              </div>
                              <div className="services__content-4">
                                 <ul>
                                    <li>Compare green hydrogen & carbon abatement metrics</li>
                                    <li>Multi-turbine analysis of wake differential and wind production</li>
                                 </ul>
                              </div>
                           </div>
                           <div className="services__item d-flex flex-lg-row flex-column">
                              <div className="services__icon me-lg-3 mb-lg-0 mb-4">
                                 <img src="/assets/img/netzero-project2.png" alt="" />
                              </div>
                              <div className="services__content-4">
                                 <ul>
                                    <li>Multi-phased use of Dsider</li>
                                    <li>Phase I - Baseline current emissions of gas processing plant in Abu Dhabi</li>
                                 </ul>
                              </div>
                           </div>
                           <div className="services__item d-flex flex-lg-row flex-column">
                              <div className="services__icon me-lg-3 mb-lg-0 mb-4">
                                 <img src="/assets/img/netzero-project3.png" alt="" />
                              </div>
                              <div className="services__content-4">
                                 <ul>
                                    <li>Mass balancing for techno-economic analysis and energy mix analysis</li>
                                 </ul>
                              </div>
                           </div>
                           <div className="services__item d-flex flex-lg-row flex-column">
                              <div className="services__icon me-lg-3 mb-lg-0 mb-4">
                                 <img src="/assets/img/netzero-project4.png" alt="" />
                              </div>
                              <div className="services__content-4">
                                 <ul>
                                    <li>Screening tool for Geothermal with levelized costs, financials, and carbon abatement</li>
                                 </ul>
                              </div>
                           </div>
                           <div className="services__item d-flex flex-lg-row flex-column">
                              <div className="services__icon me-lg-3 mb-lg-0 mb-4">
                                 <img src="/assets/img/netzero-project5.png" alt="" />
                              </div>
                              <div className="services__content-4">
                                 <ul>
                                    <li>Carbon abatement scenario analysis based on power sources for drilling</li>
                                 </ul>
                              </div>
                           </div>
                           <div className="services__item d-flex flex-lg-row flex-column">
                              <div className="services__icon me-lg-3 mb-lg-0 mb-4">
                                 <img src="/assets/img/netzero-project6.png" alt="" />
                              </div>
                              <div className="services__content-4">
                                 <ul>
                                    <li>Ammonia analysis and levelized costs across 3 locations</li>
                                 </ul>
                              </div>
                           </div>
                           <div className="services__item d-flex flex-lg-row flex-column">
                              <div className="services__icon me-lg-3 mb-lg-0 mb-4">
                                 <img src="/assets/img/netzero-project7.png" alt="" />
                              </div>
                              <div className="services__content-4">
                                 <ul>
                                    <li>Carbon emissions from DER & scenario analysis for location-based carbon reduction target</li>
                                 </ul>
                              </div>
                           </div>
                           <div className="services__item d-flex flex-lg-row flex-column">
                              <div className="services__icon me-lg-3 mb-lg-0 mb-4">
                                 <img src="/assets/img/netzero-project8.png" alt="" />
                              </div>
                              <div className="services__content-4">
                                 <ul>
                                    <li>Energy yield and carbon intensity of Syzygy's dry methane reforming technology</li>
                                 </ul>
                              </div>
                           </div>
                           <div className="services__item d-flex flex-lg-row flex-column">
                              <div className="services__icon me-lg-3 mb-lg-0 mb-4">
                                 <img src="/assets/img/netzero-project9.png" alt="" />
                              </div>
                              <div className="services__content-4">
                                 <ul>
                                    <li>Microgrid analysis for continuous baseload clean power</li>
                                    <li>Close loop analysis carbon abatement</li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="services__area-3 pt-100 pb-100">
               <div className="container">
                  <div className="row">
                     <div className="col-xl-12">
                        <div className="m-img">
                           <img src="/assets/img/netzero-img6.jpg" alt="" />
                        </div>
                     </div>
                  </div>
               </div>
            </section>
         </section>
      </>
   );
};

export default HomeServices;
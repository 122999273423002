import React from 'react';

const SingleSolutions = ({ serviceID, type }) => {

    const serviceData = [
        { title: 'Internet of Things (IoT) Solutions', description: 'Seamlessly merge IT and OT to create a Connected Enterprise, enhancing productivity and quality across sectors.' },
        { title: 'Human-Machine Interface (HMI) Solutions', description: 'Unlock efficiency and safety in manufacturing with intuitive touch screen panels, streamlining processes and empowering operators.' },
        { title: 'Industrial PC Solutions', description: "Stay ahead with robust IPC solutions, tailored to your industry's needs for optimal performance in complex control tasks." },
        { title: 'Comprehensive Control Solutions', description: 'Seamlessly integrate controls, visualization, and data analytics to optimize throughput in dynamic manufacturing environments.' },
        { title: 'Customized Software Solutions', description: 'Empower your business with bespoke software crafted by industry experts, leveraging cutting-edge technologies for competitive advantage.' },
        { title: 'Industrial Data Communication Solutions', description: 'Ensure uninterrupted operations with solutions maximizing uptime, efficiency, and performance.' },
    ];

    return (
        <>
            {/* <div className="services__item black2 d-flex align-items-center flex-lg-row flex-column mt-30"> */}
            <div className="services__item d-flex align-items-center flex-lg-row flex-column mt-30">
                {type === 'right' ? (
                    <>
                        <div className="services__img r-img me-lg-5 mb-lg-0 mb-4">
                            <img src={`/assets/img/factory-auto/solution-${Number(serviceID) + 1}.png`} alt="" />
                        </div>
                        <div className="services__content">
                            <h3>{serviceData[serviceID].title}</h3>
                            <p>{serviceData[serviceID].description}</p>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="services__content">
                            <h3>{serviceData[serviceID].title}</h3>
                            <p>{serviceData[serviceID].description}</p>
                        </div>
                        <div className="services__img r-img me-lg-5 mb-lg-0 mb-4">
                            <img src={`/assets/img/factory-auto/solutions-${Number(serviceID) + 1}.png`} alt="" />
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default SingleSolutions;
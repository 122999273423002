import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import BlogSingleItem from '../../../components/BlogSingleItem/BlogSingleItem';
import BlogLeftSideBar from './BlogLeftSideBar';
import axios from 'axios';
import { PublicListBlogsURL } from '../../../config';
import Loader from '../../../components/shared/Loader';

const BlogsArea = () => {
   const { blogCatID } = useParams();
   const [Loaded, SetLoaded] = useState(false);
   const [Blogs, SetBlogs] = useState([]);
   const FetchBlogs = async (filterObj) => {
      // Handle login logic here
      try {
          // Make API call using Axios
          const headers = { 'Content-Type': 'application/json' };
          const response = await axios.post(PublicListBlogsURL, { ...filterObj }, { headers });
          // Handle successful login (use the response data as needed)
          if (Number(response.data.status) === 1 && response.data.blogs && response.data.blogs.length > 0 ) {
              SetBlogs(response.data.blogs);
          } else {
            SetBlogs([]);
          }
          SetLoaded(true);
        } catch (error) {
          // Handle login failure
          SetLoaded(true);
          console.error('Login error:', error.response.data);
        }
  };

  useEffect(() => {
      SetLoaded(false);
      FetchBlogs({ blogCatID });
   }, [blogCatID]);

   return (
      <>
            <section className="blog__area pt-120 pb-120">
               <div className="container">
                  <div className="row">
                     <div className="col-xl-8 col-lg-8">
                        {Loaded ? (
                           <div className="blog__wrapper mr-50">
                              {Blogs.map((s) => (
                                 <div key={s.blogID}>
                                    <BlogSingleItem blogData={s} />
                                 </div>
                              ))}
                           </div>
                        ) : (
                           <Loader />
                        )}
                     </div>
                     <BlogLeftSideBar/>
                  </div>
               </div>
            </section>
         
      </>
   );
};

export default BlogsArea;
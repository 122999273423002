// import { CgArrowLongRight } from 'react-icons/cg';
import { FaCheck } from 'react-icons/fa';
import { WwwUrl } from '../../../../config';
// import { Link } from 'react-router-dom';

const HomeAbout = ({ v }) => {

   const data = [
      {
         title: 'Green Hydrogen Transition',
         description: 'We help you harness the power of green hydrogen, a clean and versatile energy carrier that can be used for various applications.',
         bullet1: "We provide you with a detailed analysis of the feasibility, costs, and benefits of green hydrogen production, storage, and utilization.",
         bullet2: "We also help you integrate green hydrogen solutions with your existing or new systems and devices, ensuring optimal performance and reliability.",
         image: "/assets/img/netzero-img1.jpg",
      },
      {
         title: 'EV Charging Station',
         description: 'We help you electrify your transportation and mobility needs with our EV charging station services.',
         bullet1: "We offer you a complete solution that includes the design, installation, operation, and maintenance of EV charging stations.",
         bullet2: "We also provide you with technical details, scalability options, and case studies of successful EV charging station projects. We help you create a smart and sustainable mobility network that meets your needs and expectations.",
         image: "/assets/img/netzero-img2.jpg",
      },
      {
         title: 'Microgrid Analysis',
         description: 'We help you improve your energy resilience and security with our microgrid analysis service.',
         bullet1: 'We help you design, develop, and deploy microgrids that can operate independently or in coordination with the main grid.',
         bullet2: 'We also help you optimize your microgrid performance, efficiency, and economics, using advanced analytics and simulation tools. We provide you with case studies and potential applications of microgrids for various sectors and scenarios.',
         image: "/assets/img/netzero-img3.jpg",
      },
      {
         title: 'Ammonia Analysis & Dry Methane Reforming Yield Modeling',
         description: 'We help you explore the potential of ammonia and dry methane reforming, two promising technologies that can enable low-carbon and circular economy.',
         bullet1: 'We provide you with detailed information on the technical specifications, operational parameters, and environmental impacts of ammonia and dry methane reforming processes.',
         bullet2: 'We also help you model the yield and profitability of these processes, using data-driven and scenario-based approaches. We help you evaluate and compare the advantages and disadvantages of these technologies for your specific use cases.',
         image: "/assets/img/netzero-img4.jpg",
      }
   ]

   return (
      <>
         <section className="about__area pt-100">
            <div className="container">
               <div className="row">
                  <div className="col-md-5">
                     <div className="m-img r-img">
                        <img src={`${WwwUrl}${data[v].image}`} alt="" />
                     </div>
                  </div>
                  <div className="col-md-7">
                     <div className="about__content">
                        <div className="section__title mb-15">
                           {/* <span>{sectionID}</span> */}
                           <h3 className="white-color">{data[v].title}</h3>
                        </div>
                        <p className="white-color">{data[v].description}</p>
                        <div className="about__list">
                           <ul>
                              {data[v].bullet1 && <li><span className="white-color"><i> <FaCheck /> </i>{data[v].bullet1}</span></li>}
                              {data[v].bullet2 && <li><span className="white-color"><i> <FaCheck /> </i>{data[v].bullet2}</span></li>}
                              {data[v].bullet3 && <li><span className="white-color"><i> <FaCheck /> </i>{data[v].bullet3}</span></li>}
                           </ul>
                        </div>
                        {/* <Link to="/services" className="z-btn " >What we do<i > <CgArrowLongRight /> </i></Link> */}
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeAbout;
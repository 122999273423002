import React from 'react';
import { FaFacebookF, FaTwitter, FaVimeoV } from 'react-icons/fa';
import BlogLeftSideBar from '../../Blogs/BlogsArea/BlogLeftSideBar';
import { UploadURL } from '../../../config';
import Loader from '../../../components/shared/Loader';


const BlogDetailsArea = ({ Blog, Loaded }) => {
   return (
      <>
         <section className="blog__area pt-120 pb-120">
            <div className="container">
               <div className="row">
                  <div className="col-xl-8 col-lg-8">
                     {Loaded ? (
                        <div className="blog__details-wrapper mr-50">
                           <div className="blog__text mb-40">
                              <h3>{Blog.title}</h3>
                           </div>
                           <div className="blog__details-thumb w-img mb-45">
                              <img src={`${UploadURL}${Blog.image}`} alt="" />
                           </div>
                           <div className="blog__text mb-40">
                              <p dangerouslySetInnerHTML={{ __html: Blog.content }} />
                           </div>
                           <div className="blog__tag mb-30 d-none">
                              <span>Tag : </span>
                              {/* <a href="#">Blog</a>
                              <a href="#">Creative</a>
                              <a href="#">Portfolio</a>
                              <a href="#">Theme</a> */}
                           </div>
                           <div className=" d-none blog__share d-flex align-items-center mb-30">
                              <span>Share : </span>
                              <div className="blog__social theme-social d-inline-block">
                                 <ul>
                                    <li>
                                       <a href="/">
                                          <i ><FaFacebookF /></i>
                                          <i ><FaFacebookF /></i>
                                       </a>
                                    </li>
                                    <li>
                                       <a href="/">
                                          <i ><FaTwitter /> </i>
                                          <i ><FaTwitter /> </i>
                                       </a>
                                    </li>
                                    <li>
                                       <a href="/">
                                          <i ><FaVimeoV /> </i>
                                          <i ><FaVimeoV /> </i>
                                       </a>
                                    </li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                     ) : (
                        <Loader />
                     )}
                  </div>

                  <BlogLeftSideBar/>
               </div>
            </div>
         </section>
      </>
   );
};

export default BlogDetailsArea;
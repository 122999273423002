import React from 'react';
import { Link } from 'react-router-dom';
import { FaCheck } from 'react-icons/fa';
import { ReactTyped } from "react-typed";
import Slider from "react-slick";

import PageHelmet from '../../../../components/shared/PageHelmet';
import HomeOneHeader from '../../../Home/HomeOneHeader/HomeOneHeader';
import Footer from '../../../../components/shared/Footer';

const settings = {
   autoplay: true,
   autoplaySpeed: 4000,
   speed: 500,
   infinite: true,
   hover: true,
   dots: true,
   fade: true,
   arrows: false,
   slidesToShow: 1,
   slidesToScroll: 1,
};

const NetZeroNew = () => {
   return (
      <>
         <PageHelmet pageTitle="Net Zero Transition" />
         <HomeOneHeader />
         <section
            className="page__title page__title-blue p-relative d-flex align-items-center fix"
            style={{ background: `url(/assets/img/page-title-netzero.jpg)`, backgroundPosition: 'center', backgroundSize: 'cover' }}
         >
            <div className="slider__shape">
               <img className="shape triangle" src="/assets/img/icon/slider/triangle.png" alt="triangle" />
               <img className="shape dotted-square" src="/assets/img/icon/slider/dotted-square.png" alt="dotted-square" />
               <img className="shape solid-square" src="/assets/img/icon/slider/solid-square.png" alt="solid-square" />
               <img className="shape circle-2" src="/assets/img/icon/slider/circle.png" alt="circle" />
            </div>
            <div className="container p-relative">
               <div className="row">
                  <div className="col-xl-12">
                     <div className="page__title-content mt-100">
                        <h2>Net Zero Transition</h2>
                        <nav aria-label="breadcrumb">
                           <ol className="breadcrumb">
                              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                              <li className="breadcrumb-item active" aria-current="page">Net Zero</li>
                           </ol>
                        </nav>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <section className="gradient-bg">
            <section className="services__area-3 pt-100 pb-100">
               <div className="container">
                  <div className="row">
                     <div className="col-xl-12">
                        <div className="section__title section__title-3 text-center mb-40">
                           <h2 className="white-color">Analyze & compare energy transition strategies to manage your entire portfolio</h2>
                        </div>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-xl-8">
                        <div className="services">
                           <div className="services__item text-center black">
                              <div className="services__icon mb-25">
                                 <img src="/assets/img/netzero-icon1.png" alt="" />
                              </div>
                              <div className="services__content">
                                 <h4>Clean Base Load Power (Wind, Solar, Geothermal) Microgrids</h4>
                              </div>
                           </div>
                           <div className="services__item text-center black">
                              <div className="services__icon mb-25">
                                 <img src="/assets/img/netzero-icon2.png" alt="" />
                              </div>
                              <div className="services__content">
                                 <h4>Carbon Capture, Utilization & Storage</h4>
                              </div>
                           </div>
                           <div className="services__item text-center black">
                              <div className="services__icon mb-25">
                                 <img src="/assets/img/netzero-icon3.png" alt="" />
                              </div>
                              <div className="services__content">
                                 <h4>Sustainable Aviation Fuel</h4>
                              </div>
                           </div>
                           <div className="services__item text-center black">
                              <div className="services__icon mb-25">
                                 <img src="/assets/img/netzero-icon4.png" alt="" />
                              </div>
                              <div className="services__content">
                                 <h4>EV Charging</h4>
                              </div>
                           </div>
                           <div className="services__item text-center black">
                              <div className="services__icon mb-25">
                                 <img src="/assets/img/netzero-icon5.png" alt="" />
                              </div>
                              <div className="services__content">
                                 <h4>Green Hydrogen / Ammonia / Methanol Analysis</h4>
                              </div>
                           </div>
                           <div className="services__item text-center black">
                              <div className="services__icon mb-25">
                                 <img src="/assets/img/netzero-icon6.png" alt="" />
                              </div>
                              <div className="services__content">
                                 <h4>Circular Economy</h4>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-4">
                        <div className="services__tab-2 black">
                           <div className="services__content-4">
                              <h3 className="white-color mb-25">Example Use Cases</h3>
                              <ul>
                                 <li>Computations of emission & abatement across multiple energy sources.</li>
                                 <li>Energy yield and cost comparisons across multiple locations.</li>
                                 <li>Cross asset analysis for clean base load power.</li>
                                 <li>Reducing non-productive time for low carbon fossil fuel production.</li>
                                 <li>Reliable and sustainable supply of green hydrogen and derivatives.</li>
                              </ul>
                              <p className="d-none d-lg-block" style={{ height: '60px' }}>&nbsp;</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="capabilities__area p-relative pt-100 pb-100 fix">
               <div
                  className="capabilities__thumb p-absolute"
                  style={{ background: `url(/assets/img/netzero-bg2.png)`, backgroundPosition: 'left center', backgroundSize: 'cover' }}>
               </div>
               <div className="container">
                  <div className="row">
                     <div className="col-xl-6">
                        <div className="capabilities__content">
                           <div className="section__title section__title mb-40">
                              <span className="white-color">Companies struggle with data driven insights to achieve energy transition</span>
                              <h2 className="white-color">What we offer is a</h2>
                           </div>
                           <div className="capabilities__list">
                              <ol>
                                 <li>No/Low Code</li>
                                 <li>AI Based Planning Tool that Automatically</li>
                                 <li>Simulates Validated Data to Bring</li>
                                 <li>Ease & Precision in Strategic Decision</li>
                                 <li>Accelerate Energy Transition</li>
                              </ol>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="services__area-3 pt-100 pb-100">
               <div className="container">
                  <div className="row">
                     <div className="col-xl-12">
                        <div className="section__title section__title-3 text-center mb-40">
                           <h2 className="white-color">We can instantly simulate transition needs with its no code platform</h2>
                        </div>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-xl-6">
                        <div className="services flex-column">
                           <div className="services__item d-flex align-items-lg-center flex-lg-row flex-column black">
                              <div className="services__icon me-lg-4 mb-lg-0 mb-4">
                                 <img src="/assets/img/netzero-icon7.png" alt="" />
                              </div>
                              <div className="services__content-4">
                                 <h4 className="text-success">SAAS PLATFORM</h4>
                                 <ul>
                                    <li className="mb-5">Integrates data, process, & analytics</li>
                                    <li className="mb-0">Creates single source of truth</li>
                                 </ul>
                              </div>
                           </div>
                           <div className="services__item d-flex align-items-lg-center flex-lg-row flex-column black">
                              <div className="services__icon me-lg-4 mb-lg-0 mb-4">
                                 <img src="/assets/img/netzero-icon8.png" alt="" />
                              </div>
                              <div className="services__content-4">
                                 <h4 className="text-success">COLLABORATION</h4>
                                 <ul>
                                    <li className="mb-5">Reduces process timelines</li>
                                    <li className="mb-0">Advances execution of NetZero plans</li>
                                 </ul>
                              </div>
                           </div>
                           <div className="services__item d-flex align-items-lg-center flex-lg-row flex-column black">
                              <div className="services__icon me-lg-4 mb-lg-0 mb-4">
                                 <img src="/assets/img/netzero-icon9.png" alt="" />
                              </div>
                              <div className="services__content-4">
                                 <h4 className="text-success">VIRTUAL SMES</h4>
                                 <ul>
                                    <li className="mb-5">Embeds expertise in models to create 24x7 virtual SMEs</li>
                                    <li className="mb-0">Eliminates need to build huge departments to do</li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-6">
                        <div className="services__tab-2 black">
                           <div className="services__content-4">
                              <ul>
                                 <li><b>Connects plans and stakeholders across value chain</b></li>
                                 <li><b>Analysis in hours vs months</b></li>
                              </ul>
                              <div className="m-img r-img mt-30">
                                 <img src="/assets/img/netzero-img5.jpg" alt="" className="m-0" />
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="about__area pt-100 pb-100">
               <div className="container">
                  <div className="row align-items-center">
                     <div className="col-xl-5">
                        <div className="section__title section__title-2">
                           <h2 className="white-color">A new, smarter way to achieve your net zero goals</h2>
                        </div>
                     </div>
                     <div className="col-xl-7">
                        <div className="services__tab-2 black">
                           <div className="services__content-5">
                              <h2 className="white-color">01</h2>
                              <h4 className="white-color">Our Net Zero Services are designed to help you accelerate your transition to low-carbon and sustainable operations.</h4>
                           </div>
                        </div>
                        <div className="services__tab-2 black">
                           <div className="services__content-5">
                              <h2 className="white-color">02</h2>
                              <h4 className="white-color">We partner with Dsider.app, a leading provider of software solutions for industrial automation, to offer you a comprehensive range of services that cover various aspects of net zero planning and implementation.</h4>
                           </div>
                        </div>
                        <div className="services__tab-2 black">
                           <div className="services__content-5">
                              <h2 className="white-color">03</h2>
                              <h4 className="white-color">Whether you need to reduce your carbon footprint, increase your energy efficiency, or adopt new technologies, we have the expertise and the tools to help you succeed.</h4>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="about__area pt-100 pb-100">
               <Slider className="text-center" {...settings}>
                  {/* Slide01 */}
                  <div className="container">
                     <div className="row align-items-center">
                        <div className="col-md-5">
                           <div className="m-img r-img">
                              <img src="/assets/img/netzero-img1.jpg" alt="" />
                           </div>
                        </div>
                        <div className="col-md-7">
                           <div className="about__content text-start">
                              <div className="section__title mb-30">
                                 <h3 className="white-color mb-20">Green Hydrogen Transition</h3>
                                 <h4 className="white-color">We help you harness the power of green hydrogen, a clean and versatile energy carrier that can be used for various applications.</h4>
                              </div>
                              <div className="about__list">
                                 <ul>
                                    <li><span className="white-color"><i> <FaCheck /> </i> We provide you with a detailed analysis of the feasibility, costs, and benefits of green hydrogen production, storage, and utilization.</span></li>
                                    <li><span className="white-color"><i> <FaCheck /> </i> We also help you integrate green hydrogen solutions with your existing or new systems and devices, ensuring optimal performance and reliability.</span></li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  {/* Slide02 */}
                  <div className="container">
                     <div className="row align-items-center">
                        <div className="col-md-5">
                           <div className="m-img r-img">
                              <img src="/assets/img/netzero-img2.jpg" alt="" />
                           </div>
                        </div>
                        <div className="col-md-7">
                           <div className="about__content text-start">
                              <div className="section__title mb-30">
                                 <h3 className="white-color mb-20">EV Charging Station</h3>
                                 <h4 className="white-color">We help you electrify your transportation and mobility needs with our EV charging station services.</h4>
                              </div>
                              <div className="about__list">
                                 <ul>
                                    {/* <li><span className="white-color"><i> <FaCheck /> </i> We offer you a complete solution that includes the design, installation, operation, and maintenance of EV charging stations.</span></li> */}
                                    <li><span className="white-color"><i> <FaCheck /> </i> We provide you with technical details, scalability options, and case studies of successful EV charging station projects. We help you create a smart and sustainable mobility network that meets your needs and expectations.</span></li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  {/* Slide03 */}
                  <div className="container">
                     <div className="row align-items-center">
                        <div className="col-md-5">
                           <div className="m-img r-img">
                              <img src="/assets/img/netzero-img3.jpg" alt="" />
                           </div>
                        </div>
                        <div className="col-md-7">
                           <div className="about__content text-start">
                              <div className="section__title mb-30">
                                 <h3 className="white-color mb-20">Microgrid Analysis</h3>
                                 <h4 className="white-color">We help you improve your energy resilience and security with our microgrid analysis service.</h4>
                              </div>
                              <div className="about__list">
                                 <ul>
                                    {/* <li><span className="white-color"><i> <FaCheck /> </i> We help you design, develop, and deploy microgrids that can operate independently or in coordination with the main grid.</span></li> */}
                                    <li><span className="white-color"><i> <FaCheck /> </i> We help you optimize your microgrid performance, efficiency, and economics, using advanced analytics and simulation tools. We provide you with case studies and potential applications of microgrids for various sectors and scenarios.</span></li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  {/* Slide04 */}
                  <div className="container">
                     <div className="row align-items-center">
                        <div className="col-md-5">
                           <div className="m-img r-img">
                              <img src="/assets/img/netzero-img4.jpg" alt="" />
                           </div>
                        </div>
                        <div className="col-md-7">
                           <div className="about__content text-start">
                              <div className="section__title mb-30">
                                 <h3 className="white-color mb-20">Ammonia Analysis & Dry Methane Reforming Yield Modeling</h3>
                                 <h4 className="white-color">We help you explore the potential of ammonia and dry methane reforming, two promising technologies that can enable low-carbon and circular economy.</h4>
                              </div>
                              <div className="about__list">
                                 <ul>
                                    <li><span className="white-color"><i> <FaCheck /> </i> We provide you with detailed information on the technical specifications, operational parameters, and environmental impacts of ammonia and dry methane reforming processes.</span></li>
                                    <li><span className="white-color"><i> <FaCheck /> </i> We also help you model the yield and profitability of these processes, using data-driven and scenario-based approaches. We help you evaluate and compare the advantages and disadvantages of these technologies for your specific use cases.</span></li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </Slider>
            </section>
            <section className="services__area-3 pt-100 pb-100">
               <div className="container">
                  <div className="row">
                     <div className="col-xl-12">
                        <div className="section__title section__title text-center mb-lg-5">
                           <h2 className="white-color">Why Choose Us</h2>
                        </div>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-xl-6 mb-lg-4">
                        <div className="services__tab-2 black">
                           <div className="services__content-4">
                              <h2 className="mb-20 white-color">01</h2>
                              <h4 className="white-color">We are a trusted and experienced company that offers a comprehensive range of net zero services to meet your needs.</h4>
                              <p className="d-none d-lg-block"><br /></p>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-6">
                        <div className="services__tab-2 black">
                           <div className="services__content-4">
                              <h2 className="mb-20 white-color">02</h2>
                              <h4 className="white-color">We have a strong partnership with Dsider.app, a leading provider of software solutions for industrial automation, that enables us to leverage the benefits of Industry 4.0 and IIoT for your net zero projects.</h4>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-6">
                        <div className="services__tab-2 black">
                           <div className="services__content-4">
                              <h2 className="mb-20 white-color">03</h2>
                              <h4 className="white-color">We have the expertise and the tools to help you plan and execute your net zero journey, from start to finish.</h4>
                              <p className="d-none d-lg-block"><br /></p>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-6">
                        <div className="services__tab-2 black">
                           <div className="services__content-4">
                              <h2 className="mb-20 white-color">04</h2>
                              <h4 className="white-color">We are not just a service provider, we are your partner in net zero. Contact us today to find out how we can help you achieve your net zero goals.</h4>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="services__area-3 pt-100 pb-100">
               <div className="container">
                  <div className="row">
                     <div className="col-xl-12">
                        <div className="section__title section__title text-center mb-lg-5">
                           <h3 className="white-color">Net zero operations require detailed planning & has several moving parts</h3>
                        </div>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-xl-12">
                        <div className="services__tab-2 black">
                           <div className="services__content-4">
                              <div className="row align-items-center">
                                 <div className="col-xl-6">
                                    {/* <h3 className="text-success">Current State</h3> */}
                                    <h4 className="black-color mb-30 mb-lg-0">Enterprise Net Zero Planning - Without Dsider</h4>
                                 </div>
                                 <div className="col-xl-6">
                                    <h4 className="white-color mb-0">
                                       {" "}
                                       <ReactTyped
                                          strings={[
                                             "Nascent Knowledge and Evolving Technologies",
                                             "Non-Existent Proven Data",
                                             "Changing Regulations",
                                             "Emerging Data Sources",
                                             "Shifting Capital Market",
                                          ]}
                                          typeSpeed={100}
                                          backSpeed={20}
                                          loop
                                       />
                                    </h4>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-xl-12">
                        <div className="services__tab-2 black">
                           <div className="services__content-4">
                              <div className="row align-items-center">
                                 <div className="col-xl-6">
                                    {/* <h3 className="text-danger mb-30 mb-lg-0">But current approaches and solutions leave key questions unanswered</h3> */}
                                    <h4 className="blue-2-color mb-30 mb-lg-0">Enterprise Net Zero Planning - With Dsider</h4>
                                 </div>
                                 <div className="col-xl-6">
                                    <h4 className="white-color mb-0">
                                       {" "}
                                       <ReactTyped
                                          strings={[
                                             "Detailed Risk Analysis",
                                             "Trade-Offs",
                                             "Granular Metrics",
                                             "Flexibility",
                                             "Prioritization",
                                          ]}
                                          typeSpeed={100}
                                          loop
                                          backSpeed={20}
                                       />
                                    </h4>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="services__area-3 pt-100 pb-50">
               <div className="container">
                  <div className="row">
                     <div className="col-xl-12">
                        <div className="section__title section__title-3 mb-40">
                           <h2 className="white-color">Dsider can instantly simulate transition needs with its no code platform</h2>
                           <span className="white-color mb-0">Address net zero priorities with ease and precision</span>
                        </div>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-xl-12">
                        {/* <div className="services__content-4">
                              <h3 className="mb-30 text-center white-color">Planning For Sustainability By Design</h3>
                           </div> */}
                        <div className="row">
                           <div className="col-xl-3">
                              <div className="services__item text-center">
                                 <div className="services__icon">
                                    <img src="/assets/img/netzero-icon10.png" alt="" className="mb-3" />
                                 </div>
                                 <div className="services__content">
                                    <h4>Forward Forecasting</h4>
                                 </div>
                              </div>
                           </div>
                           <div className="col-xl-3">
                              <div className="services__item text-center">
                                 <div className="services__icon">
                                    <img src="/assets/img/netzero-icon11.png" alt="" className="mb-3" />
                                 </div>
                                 <div className="services__content">
                                    <h4>Standardized Metrics</h4>
                                 </div>
                              </div>
                           </div>
                           <div className="col-xl-3">
                              <div className="services__item text-center">
                                 <div className="services__icon">
                                    <img src="/assets/img/netzero-icon12.png" alt="" className="mb-3" />
                                 </div>
                                 <div className="services__content">
                                    <h4>Scenario Analysis</h4>
                                 </div>
                              </div>
                           </div>
                           <div className="col-xl-3">
                              <div className="services__item text-center">
                                 <div className="services__icon">
                                    <img src="/assets/img/netzero-icon13.png" alt="" className="mb-3" />
                                 </div>
                                 <div className="services__content">
                                    <h4>Risk and Trade-Offs</h4>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="services__area-3 pt-100">
               <div className="container">
                  <div className="row">
                     <div className="col-xl-12">
                        <div className="section__title section__title-3 text-center mb-40">
                           <h2 className="white-color">References of previous projects with Dsider planning tool</h2>
                        </div>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-xl-12">
                        <div className="services">
                           <div className="services__item d-flex flex-lg-row flex-column black">
                              <div className="services__icon me-lg-3 mb-lg-0 mb-4">
                                 <img src="/assets/img/netzero-project1.png" alt="" />
                              </div>
                              <div className="services__content-4">
                                 <ul>
                                    <li>Compare green hydrogen & carbon abatement metrics</li>
                                    <li>Multi-turbine analysis of wake differential and wind production</li>
                                 </ul>
                              </div>
                           </div>
                           <div className="services__item d-flex flex-lg-row flex-column black">
                              <div className="services__icon me-lg-3 mb-lg-0 mb-4">
                                 <img src="/assets/img/netzero-project2.png" alt="" />
                              </div>
                              <div className="services__content-4">
                                 <ul>
                                    <li>Multi-phased use of Dsider</li>
                                    <li>Phase I - Baseline current emissions of gas processing plant in Abu Dhabi</li>
                                 </ul>
                              </div>
                           </div>
                           <div className="services__item d-flex flex-lg-row flex-column black">
                              <div className="services__icon me-lg-3 mb-lg-0 mb-4">
                                 <img src="/assets/img/netzero-project3.png" alt="" />
                              </div>
                              <div className="services__content-4">
                                 <ul>
                                    <li>Mass balancing for techno-economic analysis and energy mix analysis</li>
                                 </ul>
                              </div>
                           </div>
                           <div className="services__item d-flex flex-lg-row flex-column black">
                              <div className="services__icon me-lg-3 mb-lg-0 mb-4">
                                 <img src="/assets/img/netzero-project4.png" alt="" />
                              </div>
                              <div className="services__content-4">
                                 <ul>
                                    <li>Screening tool for Geothermal with levelized costs, financials, and carbon abatement</li>
                                 </ul>
                              </div>
                           </div>
                           <div className="services__item d-flex flex-lg-row flex-column black">
                              <div className="services__icon me-lg-3 mb-lg-0 mb-4">
                                 <img src="/assets/img/netzero-project5.png" alt="" />
                              </div>
                              <div className="services__content-4">
                                 <ul>
                                    <li>Carbon abatement scenario analysis based on power sources for drilling</li>
                                 </ul>
                              </div>
                           </div>
                           <div className="services__item d-flex flex-lg-row flex-column black">
                              <div className="services__icon me-lg-3 mb-lg-0 mb-4">
                                 <img src="/assets/img/netzero-project6.png" alt="" />
                              </div>
                              <div className="services__content-4">
                                 <ul>
                                    <li>Ammonia analysis and levelized costs across 3 locations</li>
                                 </ul>
                              </div>
                           </div>
                           <div className="services__item d-flex flex-lg-row flex-column black">
                              <div className="services__icon me-lg-3 mb-lg-0 mb-4">
                                 <img src="/assets/img/netzero-project7.png" alt="" />
                              </div>
                              <div className="services__content-4">
                                 <ul>
                                    <li>Carbon emissions from DER & scenario analysis for location-based carbon reduction target</li>
                                 </ul>
                              </div>
                           </div>
                           <div className="services__item d-flex flex-lg-row flex-column black">
                              <div className="services__icon me-lg-3 mb-lg-0 mb-4">
                                 <img src="/assets/img/netzero-project8.png" alt="" />
                              </div>
                              <div className="services__content-4">
                                 <ul>
                                    <li>Energy yield and carbon intensity of Syzygy's dry methane reforming technology</li>
                                 </ul>
                              </div>
                           </div>
                           <div className="services__item d-flex flex-lg-row flex-column black">
                              <div className="services__icon me-lg-3 mb-lg-0 mb-4">
                                 <img src="/assets/img/netzero-project9.png" alt="" />
                              </div>
                              <div className="services__content-4">
                                 <ul>
                                    <li>Microgrid analysis for continuous baseload clean power</li>
                                    <li>Close loop analysis carbon abatement</li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="services__area-3 pt-100">
               <div className="container">
                  <div className="row">
                     <div className="col-xl-12">
                        <div className="m-img">
                           <img src="/assets/img/netzero-img6.jpg" alt="" />
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="services__area pt-100 pb-100">
               <div className="container-fluid">
                  <div className="row align-items-center">
                     <div className="col-xl-6">
                        <div className="d-flex">
                           <video autoPlay muted loop id="video" style={{ width: '100%' }}>
                              <source src="https://video.wixstatic.com/video/175f67_27ccb6c2c458435db75d33cb41ca4f78/1080p/mp4/file.mp4" type="video/mp4" />
                           </video>
                        </div>
                     </div>
                     <div className="col-xl-5 offset-xl-1">
                        <div className="about__content">
                           <div className="section__title">
                              <h3 className="white-color mb-0">"Revolutionize your EV network planning with Dsider app's scenario modeling, now enhanced with generative AI for smarter, data-driven decisions."</h3>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
         </section>
         <Footer />
      </>
   );
};

export default NetZeroNew;
import React, { useState, useEffect } from 'react';
import { FaSearch } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { PublicListBlogsURL, UploadURL, getBlogURL, showfulldatetime } from '../../../config';

const BlogLeftSideBar = () => {
    const [Blogs, SetBlogs] = useState([]);
    const [Categories, SetCategories] = useState([]);
    const FetchBlogs = async (filterObj) => {
      // Handle login logic here
      try {
          // Make API call using Axios
          const headers = { 'Content-Type': 'application/json' };
          const response = await axios.post(PublicListBlogsURL, { ...filterObj }, { headers });
          // Handle successful login (use the response data as needed)
          if (Number(response.data.status) === 1 && response.data.blogs && response.data.blogs.length > 0 ) {
              SetBlogs(response.data.blogs);
          }
          if (Number(response.data.status) === 1 && response.data.blogCategories && response.data.blogCategories.length > 0 ) {
            SetCategories(response.data.blogCategories);
        }
        } catch (error) {
          // Handle login failure
          console.error('Login error:', error.response.data);
        }
    };

    useEffect(() => {
        FetchBlogs({ limit: 3, page: 1, sortBy: 'latest' });
    }, []);
    return (
        <>
            <div
                className="col-xl-4 col-lg-4"
                style={{
                    position: 'sticky',
                    top: '100px',
                    height: 'fit-content',
                }}
            >
                <div className="blog__sidebar">
                    <div className="d-none sidebar__widget mb-50 ">
                        <div className="sidebar__widget-content">
                            <div className="search">
                                <form action="#">
                                    <input type="text" placeholder="Search..." />
                                    <button type="button"><i > <FaSearch /> </i></button>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div className="sidebar__widget mb-75 ">
                        <div className="sidebar__widget-title mb-50">
                            <h4>Categories</h4>
                        </div>
                        <div className="sidebar__widget-content">
                            <div className="cat-link">
                                <ul>
                                    {Categories.map((s) => (
                                        <li key={s.blogCatID}>
                                            <Link to={`/blogs/${s.blogCatID}`}>{s.title} ({s.total})</Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>

                    
                    <div className="sidebar__widget mb-75 ">
                        <div className="sidebar__widget-title mb-50">
                            <h4>Recent Blogs</h4>
                        </div>
                        <div className="sidebar__widget-content">
                            <div className="rc-post">
                                <ul>
                                    {Blogs.map((s) => (
                                        <li className="d-flex mb-20" key={s.blogID}>
                                            <div className="rc-thumb mr-15">
                                                <Link to={getBlogURL(s.slug, s.blogID)}>
                                                    <img
                                                        src={`${UploadURL}${s.image}`}
                                                        alt={s.title}
                                                        style={{ maxWidth: '100px' }}
                                                    />
                                                </Link>
                                            </div>
                                            <div className="rc-text">
                                                <h6><Link to={getBlogURL(s.slug, s.blogID)}>{s.title}</Link></h6>
                                                <div className="rc-meta"><span>{showfulldatetime(s.updatedAt)}</span> </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="d-none sidebar__widget mb-50 ">
                        <div className="sidebar__widget-title mb-50">
                            <h4>Popular Tags</h4>
                        </div>
                        <div className="sidebar__widget-content">
                            <div className="tags">
                                <Link to="/blogDetails">The Saas</Link>
                                <Link to="/blogDetails">Pix Saas Blog</Link>
                                <Link to="/blogDetails">Landing</Link>
                                <Link to="/blogDetails">UI/UX Design</Link>
                                <Link to="/blogDetails">Branding</Link>
                                <Link to="/blogDetails">Animation</Link>
                                <Link to="/blogDetails">Design</Link>
                                <Link to="/blogDetails">Ideas</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BlogLeftSideBar;
import React from 'react';
import { Link } from 'react-router-dom';

import PageHelmet from '../../../../components/shared/PageHelmet';
import HomeOneHeader from '../../../Home/HomeOneHeader/HomeOneHeader';
import CommonCtaArea from '../../../../components/CommonCtaArea/CommonCtaArea';
import Footer from '../../../../components/shared/Footer';

const MicroalgaeNew = () => {
    return (
        <>
            <PageHelmet pageTitle="Bio Carbon Capture & Utilization" />
            <HomeOneHeader />
            <section
                className="page__title page__title-green p-relative d-flex align-items-center fix"
                style={{
                    background: `url(/assets/img/page-title-microalgae.jpg)`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                }}
            >
                <div className="slider__shape">
                    <img className="shape triangle" src="/assets/img/icon/slider/triangle.png" alt="triangle" />
                    <img className="shape dotted-square" src="/assets/img/icon/slider/dotted-square.png" alt="dotted-square" />
                    <img className="shape solid-square" src="/assets/img/icon/slider/solid-square.png" alt="solid-square" />
                    <img className="shape circle-2" src="/assets/img/icon/slider/circle.png" alt="circle" />
                </div>
                <div className="container p-relative">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="page__title-content mt-100">
                                <h2>Bio Carbon Capture & Utilization</h2>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Carbon Capture</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="services__area-3 green-bg pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title text-center">
                                <h2 className="white-color mb-30">
                                    Helping Carbon Heavy Industries<br />Reduce Emissions Through
                                </h2>
                                <h3 className="white-color mb-30">
                                    Scalable<br />
                                    Bio Carbon Capture<br />
                                    Carbon Utilization<br />
                                </h3>
                                <h4 className="white-color mb-0">CO2 is one of the main greenhouse gases that contributes to global warming and climate change.</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <section className="services__area-3 green-bg pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title text-center">
                                <h2 className="white-color mb-0">What if we could turn CO2 from a problem into a solution?</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services__area-3 green-bg pb-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-5">
                            <div className="section__title section__title">
                                <p className="font-40 white-color mb-0">
                                    We can help you convert your CO2 emissions into valuable products, such as biofuels, bioplastics, chemicals, and fertilizers in a way that is environmentally friendly, economically viable, and socially beneficial.
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-7">
                            <div className="m-img mt-lg-0 mt-50">
                                <img src="/assets/img/microalgae-img1.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services__area-3 green-bg pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title text-center">
                                <h2 className="white-color mb-50">We offer carbon bio-utilization as a service</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="m-img mt-20">
                                <img src="/assets/img/microalgae/we-offers-new.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="services__area-3 page__title page__title-green1 p-relative green-bg pt-100 pb-100 fix"
                style={{ background: `url(/assets/img/microalgae/bg-img1.jpg)`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundAttachment: 'fixed' }}
            >
                <div className="container-fluid p-relative">
                    <div className="row align-items-center">
                        <div className="col-xl-6">
                            <div className="section__title section__title-3">
                                <h2 className="white-color mb-50">Microalgae are microscopic aquatic organisms that can perform photosynthesis, converting CO2 and water into biomass and oxygen.</h2>
                                <h4 className="white-color mb-30">They are one of the most efficient and versatile biological resources for a circular economy, because they can:</h4>
                                {/* <video style={{ width: '100%' }} autoPlay loop muted>
                                    <source src="/assets/img/microalgae/bannerVideo.mp4" type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video> */}
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="services__tab-2 noBgBrd mb-30">
                                <div className="services__content-5">
                                    <h2 className="white-color">01</h2>
                                    <h4 className="white-color">Capture & utilize CO2 from various sources, such as Power Plants, Cement Factories, & Aquaculture Farms.</h4>
                                </div>
                            </div>
                            <div className="services__tab-2 mb-30 noBgBrd">
                                <div className="services__content-5">
                                    <h2 className="white-color">02</h2>
                                    <h4 className="white-color">Grow in various water sources, such as seawater, wastewater, and brackish water, saving freshwater resources and improving water quality.</h4>
                                </div>
                            </div>
                            <div className="services__tab-2 noBgBrd">
                                <div className="services__content-5">
                                    <h2 className="white-color">03</h2>
                                    <h4 className="white-color">Produce a wide range of valuable products, such as biofuels, bioplastics, chemicals, and fertilizers, replacing fossil fuels and petrochemicals.</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container p-relative">
                    <div className="row">
                        <div className="col-xl-9 offset-lg-1">
                            <div className="m-img mt-100">
                                <img src="/assets/img/microalgae/protein-img1.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="services__area-3 green-bg pt-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title text-center">
                                <h2 className="white-color mb-0">In partnership with Algoliner & MeMO3</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <section className="services__area-3 green-bg pt-100 pb-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6">
                            <div className="section__title section__title">
                                <h2 className="white-color mb-30">What sets us apart?</h2>
                                <h4 className="white-color mb-30">We are the 1st in India to provide turnkey microalgae closed next-generation phaobioreactors.</h4>
                                <p className="white-color mb-10">We are revolutionizing microalgae cultivation in india with 10x yield then traditional methodes.</p>
                                <p className="white-color mb-0">We deploy next generation phaobioreactors that are closed tubular that allows continuous manufacturing & in line monitoring for ultra large scale operations.</p>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="m-img r-img text-lg-end mt-lg-0 mt-30">
                                <img src="/assets/img/microalgae/whatsets.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services__area-3 green-bg pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title text-center mb-50">
                                <h3 className="white-color mb-30">We offer intelligent turnkey solutions for microalgae cultivation, monitoring & harvest</h3>
                                <h4 className="white-color mb-0">We aim to promote sustainable practices to its clients in India and abroad, and help them to implement sustainable engineering solutions that benefit both their business and the environment.</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="m-img">
                                <img src="/assets/img/microalgae/approche-img1.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="services__area-3 green-bg pt-100 pb-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-4">
                            <div className="section__title section__title">
                                <h2 className="white-color mb-30">R&D</h2>
                                {/* <p className="white-color">Our Frontline Automation Platform 'Tulip' is helping companies around the world equip their workforce with the skills, tools, and insights they need to perform at their best.</p> */}
                                <h4 className="white-color mb-0">Collaboration with research institutes & partners in Switzerland & Germany to develop micro-algae prototypes with our patented membrane technology.</h4>
                            </div>
                        </div>
                        <div className="col-xl-1"></div>
                        <div className="col-xl-7">
                            <div className="m-img mt-lg-0 mt-30">
                                <img src="/assets/img/microalgae/lab.webp" style={{ width: '100%' }} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="services__area-3 green-bg pt-100 pb-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-7">
                            <div className="m-img mt-lg-0 mb-30">
                                <img style={{ width: '100%' }} src="/assets/img/microalgae/pilot.webp" alt="" />
                            </div>
                        </div>
                        <div className="col-xl-1"></div>
                        <div className="col-xl-4">
                            <div className="section__title section__title">
                                <h2 className="white-color mb-30">Pilot Operations</h2>
                                <h4 className="white-color mb-0">Our technology is the leader in the industry, with experience in scaling-up prototypes of our experimental unit to provide the most optimal micro-algae solution.</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="services__area-3 green-bg pt-100 pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-xl-4">
                            <div className="section__title section__title">
                                <h2 className="white-color mb-30">Large Scale Turnkey Solutions</h2>
                                <h4 className="white-color mb-0">Turnkey solutions with proprietary factory in a box set up unit for large-scale commissioning with patented equipment installation.</h4>
                            </div>
                        </div>
                        <div className="col-xl-1"></div>
                        <div className="col-xl-7">
                            <div className="m-img mt-lg-0 mt-30">
                                <img src="/assets/img/microalgae/largeScale2.webp" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="services__area-3 green-bg pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4">
                            <div className="services__item green mb-30 mb-lg-0">
                                <div className="services__img m-img r-img mb-30">
                                    <img src="/assets/img/microalgae-img3.png" alt="" />
                                </div>
                                <div className="services__content">
                                    <h3>R&D</h3>
                                    <p>Collaboration with research institutes & partners in Switzerland & Germany to develop micro-algae prototypes with our patented membrane technology.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="services__item green mb-30 mb-lg-0">
                                <div className="services__img m-img r-img mb-30">
                                    <img src="/assets/img/microalgae-img4.png" alt="" />
                                </div>
                                <div className="services__content">
                                    <h3>Pilot Operations</h3>
                                    <p>Our technology is the leader in the industry, with experience in scaling-up prototypes of our experimental unit to provide the most optimal micro-algae solution.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="services__item green">
                                <div className="services__img m-img r-img mb-30">
                                    <img src="/assets/img/microalgae-img5.png" alt="" />
                                </div>
                                <div className="services__content">
                                    <h3>Large Scale Turnkey Solutions</h3>
                                    <p>Turnkey solutions with proprietary factory in a box set up unit for large-scale commissioning with patented equipment installation.</p>
                                    <p className="d-none d-lg-block"><br /></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <div
                style={{
                    background: 'url(/assets/img/microalgae/ourTech.jpg)',
                    backgroundAttachment: 'fixed',
                    backgroundSize: 'cover',
                    position: 'relative',
                }}
            >
                <div
                    style={{
                        content: '',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        background: 'rgba(0, 0, 0, 0.5)',
                    }}
                ></div>
                <section
                    className="services__area-3 green-bg1 pt-100"
                    style={{ position: 'sticky', zIndex: 9 }}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="section__title section__title text-center">
                                    <h3 className="white-color mb-0">As compared to other existing micro-algae technology providers worldwide</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section
                    className="services__area-3 green-bg1 pt-100 pb-100"
                    style={{ position: 'sticky', zIndex: 9 }}
                >
                    <div className="container">
                        <div className="row align-items-center">
                            {/* <div className="col-xl-3">
                                <div className="m-img mt-lg-0 mt-30">
                                <img src="/assets/img/microalgae/ourTech.jpg" alt="" />
                            </div>
                        </div> */}
                            <div className="col-xl-12"></div>
                            <div className="col-xl-12">

                                <div className="services">
                                    <div className="services__item text-center green ">
                                        <div className="services__content">
                                            <h4>30% higher yield as compared to other closed photobioreactors</h4>
                                        </div>
                                    </div>
                                    <div className="services__item text-center green">
                                        <div className="services__content">
                                            <h4>More precise control and monitoring with state of the art sensors</h4>
                                        </div>
                                    </div>
                                    <div className="services__item text-center green">
                                        <div className="services__content">
                                            <h4>Higher automation level possible</h4>
                                        </div>
                                    </div>
                                    <div className="services__item text-center green">
                                        <div className="services__content">
                                            <h4>Low water consumption due to closed system</h4>
                                        </div>
                                    </div>
                                    <div className="services__item text-center green">
                                        <div className="services__content">
                                            <h4>Proprietary Mem03 membranes for Oxygen removal for enhanced culturing</h4>
                                        </div>
                                    </div>
                                    <div className="services__item text-center green">
                                        <div className="services__content">
                                            <h4>Automated CIP with our smart pig system</h4>
                                        </div>
                                    </div>
                                    <div className="services__item text-center green">
                                        <div className="services__content">
                                            <h4>Micro-algae buyback</h4>
                                        </div>
                                    </div>
                                    <div className="services__item text-center green">
                                        <div className="services__content">
                                            <h4>All reactors can be equipped with integrated illumination</h4>
                                        </div>
                                    </div>
                                    <div className="services__item text-center green">
                                        <div className="services__content">
                                            <h4>Patented Membrane Tech for highly efficient filtered CO2 capture</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


            {/* <section className="services__area-3 green-bg pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                        </div>
                    </div>
                </div>
            </section> */}
            <CommonCtaArea />
            <Footer />
        </>
    );
};

export default MicroalgaeNew;
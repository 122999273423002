import React from 'react';
import { Offcanvas } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Collapsible from 'react-collapsible';

const Sidebar = ({ show, handleClose }) => {

   return (
      <>
         <div>
            <Offcanvas show={show} onHide={handleClose} placement='end' className='side__bar'>
               <Offcanvas.Header closeButton></Offcanvas.Header>
               <Offcanvas.Body>
                  <section>
                     <div className="p-0">
                        <div className='side_navBar unClick'>
                           <div className='about iconAdd'>
                              <NavLink to="/">Home</NavLink>
                           </div>

                           <div className='about iconAdd'>
                              <NavLink to="/about">About Us</NavLink>
                           </div>

                           <Collapsible trigger={<NavLink to="/services/SmartFactory" className="link">Smart Factory</NavLink>} triggerTagName="div"
                              triggerOpenedClassName="icon_close" triggerClassName="iconAdd" open={false}>
                              <ul className="sidebar_sub_menu submenu text-white">
                                 <li><NavLink to="/services/SmartFactory/FrontlineAutomation">Frontline Automation</NavLink></li>
                                 <li><NavLink to="/services/SmartFactory/FactoryAutomation">Factory Automation</NavLink></li>
                                 <li><NavLink to="/services/SmartFactory/DigitalRoadmapping">Digital Road mapping</NavLink></li>
                              </ul>
                           </Collapsible>

                           <div className='about iconAdd'>
                              <NavLink to="/services/NetZeroTransition">Net Zero Transition</NavLink>
                           </div>

                           {/* <Collapsible trigger={<NavLink to="/services/NetZero" className="link">Net Zero</NavLink>} triggerTagName="div"
                              triggerOpenedClassName="icon_close" triggerClassName="iconAdd" open={false}>
                              <ul className="sidebar_sub_menu submenu text-white">
                                 <li><NavLink to="/services/NetZero/GreenHydrogenTransition">Green Hydrogen Transition</NavLink></li>
                                 <li><NavLink to="/services/NetZero/EVChargingStation">EV Charging Station</NavLink></li>
                                 <li><NavLink to="/services/NetZero/MicrogridAnalysis">Microgrid Analysis</NavLink></li>
                                 <li><NavLink to="/services/NetZero/AmmoniaAnalysis">Ammonia Analysis and Dry Methane Reforming Yield Modeling</NavLink></li>
                              </ul>
                           </Collapsible> */}

                           <Collapsible trigger={<NavLink to="/services/CarbonCapture" className="link">Carbon Capture</NavLink>} triggerTagName="div"
                              triggerOpenedClassName="icon_close" triggerClassName="iconAdd" open={false}>
                              <ul className="sidebar_sub_menu submenu text-white">
                                 <li><NavLink to="/services/CarbonCapture/Microalgae">Microalgae</NavLink></li>
                                 {/* <li><NavLink to="/services/CarbonCapture/Membarane">Membarane</NavLink></li> */}
                              </ul>
                           </Collapsible>

                           {/* <Collapsible trigger={<NavLink to="/services/Solutions" className="link">Solutions</NavLink>} triggerTagName="div"
                              triggerOpenedClassName="icon_close" triggerClassName="iconAdd" open={false}>
                              <ul className="sidebar_sub_menu submenu text-white">
                                 <li><NavLink to="/services/Solutions/WorkforceForecasting">Workforce Forecasting</NavLink></li>
                                 <li><NavLink to="/services/Solutions/COGSModeling">COGS Modeling</NavLink></li>
                                 <li><NavLink to="/services/Solutions/FactoryDigitalTwin">Factory Digital Twin</NavLink></li>
                              </ul>
                           </Collapsible> */}

                           <div className='about iconAdd border-0'>
                              <NavLink to="/blogs">Blog</NavLink>
                           </div>

                           <div className='about iconAdd border-0'>
                              <NavLink to="/career">Career</NavLink>
                           </div>

                           <div className='about iconAdd border-0'>
                              <NavLink to="/contact">Contact Us</NavLink>
                           </div>

                        </div>
                     </div>
                  </section>
               </Offcanvas.Body>
            </Offcanvas>
         </div>
      </>
   );
};

export default Sidebar;
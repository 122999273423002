import React from 'react';

const WokforceAutomation = () => {
    return (
        <>
            <section className="services__area-3 pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6">
                            <div className="services__content-3">
                                <h3>A new, smarter way to run your workforce.</h3>
                                <div className="services__icon-wrapper">
                                    <div className="services__icon-item">
                                        <div className="icon">
                                            <img src="/assets/img/wokforce-icon1.jpg" alt="" />
                                        </div>
                                        <div className="text">
                                            <p>Our Workforce Automation Platform 'Tulip' is helping companies around the world equip their workforce with the skills, tools, and insights they need to perform at their best.</p>
                                        </div>
                                    </div>
                                    <div className="services__icon-item">
                                        <div className="icon">
                                            <img src="/assets/img/wokforce-icon2.jpg" alt="" />
                                        </div>
                                        <div className="text">
                                            <p>Digitally transform workflows, guide employees, and gain real-time visibility into the performance, productivity, and engagement of your workforce-.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="services__content-3">
                                <h3>The Next-Gen Approach Trusted by Top Companies.</h3>
                                <div className="services__icon-wrapper">
                                    <div className="services__icon-item">
                                        <div className="icon">
                                            <img src="/assets/img/wokforce-icon5.jpg" alt="" />
                                        </div>
                                        <div className="text">
                                            <p>Across various industries, leading companies are transforming their workforce by enabling citizen developers and standardizing best practices. Our customers see increases in retention, engagement, and satisfaction; faster improvement cycles, and they have achieved up to a 90% reduction in errors, 80% reduction in turnover, and 40% faster time-to-market.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="services__content-3">
                                <h3>Connected apps power transformation.</h3>
                                <div className="services__icon-wrapper">
                                    <div className="services__icon-item">
                                        <div className="icon">
                                            <img src="/assets/img/wokforce-icon3.jpg" alt="" />
                                        </div>
                                        <div className="text">
                                            <p>Empower the managers and leaders closest to the workforce to create intuitive, integrated apps with a no-code, visual interface.</p>
                                        </div>
                                    </div>
                                    <div className="services__icon-item">
                                        <div className="icon">
                                            <img src="/assets/img/wokforce-icon4.jpg" alt="" />
                                        </div>
                                        <div className="text">
                                            <p>Use our Tulip platform to upskill your employees with training, coaching, and feedback, ensure quality and compliance, monitor goals, track KPIs, and more. Achieve faster time-to-value and higher return on investment by focusing on the most impactful problems first. Expand to interconnected systems of apps that integrate and optimize your full workforce processes.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="services__content-3">
                                <h3>Our platform is secure and compliant.</h3>
                                <div className="services__icon-wrapper">
                                    <div className="services__icon-item">
                                        <div className="icon">
                                            <img src="/assets/img/wokforce-icon6.jpg" alt="" />
                                        </div>
                                        <div className="text">
                                            <p>We know that security and compliance are the first concerns of companies in any industry. We use Tulip's platform to make security and compliance a seamless part of the workforce automation. Our platform's features and architecture are designed to meet the highest standards of security and compliance.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="services__tab">
                                <div className="services__content-4">
                                    <div className="m-img text-center">
                                        <img src="/assets/img/tulip-1.jpg" alt="" />
                                    </div>
                                    <div className="m-img text-center">
                                        <img src="/assets/img/tulip-2.jpg" alt="" />
                                    </div>
                                    <div className="m-img text-center">
                                        <img src="/assets/img/tulip-3.jpg" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="services__area-3 grey-bg-12 pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-3 text-center mb-lg-5">
                                <h2>Tulip's Platform Consists of Four Components</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-3">
                            <div className="services__tab-2">
                                <div className="services__content-4 m-img">
                                    <img src="/assets/img/wokforce-img4.jpg" alt="" />
                                    <h4>Web-Based App Editor</h4>
                                    <p>Add logic to your operations without writing any code - using our apps editor, you can make simple work of complex applications.</p>
                                    <br />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3">
                            <div className="services__tab-2">
                                <div className="services__content-4 m-img">
                                    <img src="/assets/img/wokforce-img5.jpg" alt="" />
                                    <h4>Edge + Connectors</h4>
                                    <p>Connecting all of your IIoT devices, machines, and systems to Tulip is simple with Edge 10 and Edge MC. Get started implementing Tulip into your operations immediately.</p>
                                    <br />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3">
                            <div className="services__tab-2">
                                <div className="services__content-4 m-img">
                                    <img src="/assets/img/wokforce-img6.jpg" alt="" />
                                    <h4>Real-Time Analytics</h4>
                                    <p>Using the Tulip dashboard, you can measure, monitor, and make visible newly actionable process data through visual analytics.</p>
                                    <br /><br />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3">
                            <div className="services__tab-2">
                                <div className="services__content-4 m-img">
                                    <img src="/assets/img/wokforce-img7.jpg" alt="" />
                                    <h4>Ecosystem & Content</h4>
                                    <p>Download best-in-class apps and connectors from the Tulip Library. Learn from free online courses on Tulip University. Chat with experienced users and staff on our Community.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
        </>
    );
};

export default WokforceAutomation;
import { useState } from 'react';
import { FaPhoneAlt, FaEnvelope, FaSearch, FaLinkedinIn, FaWhatsapp } from 'react-icons/fa'; // FaFacebookF, FaTwitter
import { Link, NavLink } from 'react-router-dom';
import Sidebar from '../../../components/Sidebar/Sidebar';
import useGlobalContext from '../../../hooks/useGlobalContext';
import { contactEmail, contactPhone, contactWhatsapp } from '../../../config';

const HomeOneHeader = () => {
   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   const { stickyMenu } = useGlobalContext();
   return (
      <>
         <header>
            <div className="header__area p-relative header__transparent">
               <div className="header__top d-none d-md-block">
                  <div className="container">
                     <div className="row align-items-center">
                        <div className="col-xl-6 col-lg-5 col-md-4">
                           <div className="header__social">
                              <ul>
                                 {/* <li><a target="_BLANK" rel="noreferrer" href="https://www.facebook.com/"><i ><FaFacebookF /></i> </a></li>
                                 <li><a target="_BLANK" rel="noreferrer" href="https://twitter.com/"><i ><FaTwitter /> </i></a> </li> */}
                                 <li><a target="_BLANK" rel="noreferrer" href="https://www.linkedin.com/company/iiot-innovation/"><i ><FaLinkedinIn /></i></a></li>
                              </ul>
                           </div>
                        </div>
                        <div className="col-xl-6 col-lg-7 col-md-8">
                           <div className="header__info f-right">
                              <ul>
                                 <li>
                                    <a href={`tel:${contactPhone}`}>
                                       <i> <FaPhoneAlt /> </i>
                                       {contactPhone}
                                    </a>
                                 </li>
                                 <li>
                                    <a href={`mailto:${contactEmail}`}>
                                       <i> <FaEnvelope /> </i>
                                       {contactEmail}
                                    </a>
                                 </li>
                                 <li>
                                    <a href={contactWhatsapp} target="_BLANK" rel="noreferrer" className="search-toggle">
                                       <i > <FaWhatsapp /> </i>
                                    </a>
                                 </li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div id="header__sticky" className={stickyMenu ? "sticky header__bottom" : "header__bottom"}>
                  <div className="container">
                     <div className="row align-items-center">
                        <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6 col-6">
                           <div className="logo">
                              <NavLink to="/">
                                 <img src="/logo-white.png" alt="logo" />
                              </NavLink>
                           </div>
                           <div className="logo-gradient">
                              <NavLink to="/">
                                 <img src="/logo-white.png" alt="logo" />
                              </NavLink>
                           </div>
                        </div>
                        <div className="col-xl-10 col-lg-10 col-md-6 col-sm-6 col-6">
                           <div className="header__bottom-right d-flex justify-content-end align-items-center">
                              <div className="main-menu menu_wrapper_one">
                                 <nav id="mobile-menu">
                                    <ul className="unClick">
                                       <li>
                                          <NavLink to="/">
                                             <i className='fa fa-home'></i>
                                          </NavLink>
                                       </li>
                                       <li><NavLink to="/about">About Us </NavLink></li>
                                       <li>
                                          <NavLink to="/services/SmartFactory" className="link">Smart Factory</NavLink>
                                          <ul className="submenu">
                                             <li><NavLink to="/services/SmartFactory/FrontlineAutomation">Frontline Automation</NavLink></li>
                                             <li><NavLink to="/services/SmartFactory/FactoryAutomation">Factory Automation</NavLink></li>
                                             <li><NavLink to="/services/SmartFactory/DigitalRoadmapping">Digital Road mapping</NavLink></li>
                                          </ul>
                                       </li>
                                       <li>
                                          <NavLink to="/services/NetZeroTransition">Net Zero Transition</NavLink>
                                          {/* <ul className="submenu">
                                             <li><NavLink to="/services/NetZero/GreenHydrogenTransition">Green Hydrogen Transition</NavLink></li>
                                             <li><NavLink to="/services/NetZero/EVChargingStation">EV Charging Station</NavLink></li>
                                             <li><NavLink to="/services/NetZero/MicrogridAnalysis">Microgrid Analysis</NavLink></li>
                                             <li><NavLink to="/services/NetZero/AmmoniaAnalysis">Ammonia Analysis and Dry Methane Reforming Yield Modeling</NavLink></li>
                                          </ul> */}
                                       </li>
                                       <li>
                                          <NavLink to="/services/CarbonCapture" className="link">Carbon Capture</NavLink>
                                          <ul className="submenu">
                                             <li><NavLink to="/services/CarbonCapture/Microalgae">Microalgae</NavLink></li>
                                             {/* <li><NavLink to="/services/CarbonCapture/Membarane">Membarane</NavLink></li> */}
                                          </ul>
                                       </li>
                                       {/* <li>
                                          <NavLink to="/services/Solutions" className="link">Solutions</NavLink>
                                          <ul className="submenu">
                                             <li><NavLink to="/services/Solutions/WorkforceForecasting">Workforce Forecasting</NavLink></li>
                                             <li><NavLink to="/services/Solutions/COGSModeling">COGS Modeling</NavLink></li>
                                             <li><NavLink to="/services/Solutions/FactoryDigitalTwin">Factory Digital Twin</NavLink></li>
                                          </ul>
                                       </li> */}
                                       <li>
                                          <NavLink to="/blogs">Blog</NavLink>
                                          {/* <ul className="submenu">
                                             <li><NavLink to="/blogs">Blog</NavLink></li>
                                             <li><NavLink to="/blogDetails">Blog Details</NavLink></li>
                                          </ul> */}
                                       </li>
                                       <li><NavLink to="/career">Career</NavLink></li>
                                       {/* <li><NavLink to="/contact">Contact Us</NavLink></li> */}
                                    </ul>
                                 </nav>
                              </div>
                              <div className="header__btn d-none d-sm-block d-lg-none d-xl-block ml-50">
                                 <Link to="/contact" className="z-btn z-btn-white">Get in Touch</Link>
                              </div>
                              <div onClick={handleShow} className="sidebar__menu d-lg-none">
                                 <div className="sidebar-toggle-btn" id="sidebar-toggle">
                                    <span className="line"></span>
                                    <span className="line"></span>
                                    <span className="line"></span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="header__search-wrapper">
                  <div className="container">
                     <div className="row">
                        <div className="col-xl-12">
                           <form action="#">
                              <input type="text" placeholder="Your Keywords" />
                              <button type="button"><i > <FaSearch /> </i></button>
                           </form>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="body-overlay-2"></div>
            </div>
         </header>

         <Sidebar show={show} handleClose={handleClose} />

      </>
   );
};

export default HomeOneHeader;
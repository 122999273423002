import React from 'react';

const AboutPage = () => {
    return (
        <>
            <section className="about__area black-bg pb-100 pt-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-5">
                            <div className="about__thumb m-img r-img">
                                <img src="assets/img/about-img1.jpg" alt="" />
                            </div>
                        </div>
                        <div className="col-xl-6 offset-xl-1">
                            <div className="about__content">
                                <div className="section__title mb-25">
                                    <h3 className="white-color">Empowering Industries, Envisioning Sustainability</h3>
                                </div>
                                <p className="white-color mb-0">Welcome to IIOT Innovation, where the future of industrial evolution is being redefined. Our mission is simple yet profound: to lead the charge in creating a sustainable and efficient future for industries in the era of Industry 4.0.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services__area black-bg pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title text-center mb-50">
                                <h2 className="white-color">Our Three Core Pillars</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-4">
                            <div className="services__item text-center">
                                <div className="services__icon mb-30">
                                    <img src="/assets/img/about-icon1.png" alt="" />
                                </div>
                                <div className="services__content">
                                    <h3>Smart Factory Solutions</h3>
                                    <p>We transform traditional production lines into intelligent, interconnected powerhouses of efficiency.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="services__item text-center">
                                <div className="services__icon mb-30">
                                    <img src="/assets/img/about-icon2.png" alt="" />
                                </div>
                                <div className="services__content">
                                    <h3>Net Zero Transition</h3>
                                    <p>We drive the energy transition towards a zero-emissions future, making sustainability an achievable target.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="services__item text-center">
                                <div className="services__icon mb-30">
                                    <img src="/assets/img/about-icon3.png" alt="" />
                                </div>
                                <div className="services__content">
                                    <h3>Carbon Capture Innovation</h3>
                                    <p>We pioneer cutting-edge carbon capture and utilization initiatives, turning environmental challenges into opportunities.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-8 offset-xl-2">
                            <div className="text-center mt-40">
                                <h4 className="white-color mb-0">Join us as we advance technology and practices that not only minimize environmental impact but also maximize productivity and resource utilization. Together, we can forge a path to a greener, smarter world.</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AboutPage;
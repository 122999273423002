import React, { useState } from 'react';
import { AdminLoginURL, emailRegex } from '../../config';
import axios from 'axios';

const LoginForm = ({ SetIsLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [eye, seteye] = useState(false);

    const handleLogin = async (e) => {
        e.preventDefault();
        // Handle login logic here
        try {
            // Make API call using Axios
            const response = await axios.post(AdminLoginURL, { email: username, password: password });
            // Handle successful login (use the response data as needed)
            if (Number(response.data.status) === 1 && response.data.token && response.data.token !== '0' && response.data.token !== '' ) {
                SetIsLogin(true);
                localStorage.setItem('adminToken', response.data.token);
            } else {
                SetIsLogin(false);
                localStorage.setItem('adminToken', 0);
            }
          } catch (error) {
            // Handle login failure
            console.error('Login error:', error.response.data);
          }
    };

    function showPassword() {
        seteye(!eye);
    }

   return (
      <>
         <section className="about__area pb-4 pt-4">
            <div className="container">
               <div className="row">
                <div className="col-xl-8 offset-xl-2 col-lg-8 offset-lg-2">
                    <div className="row adminForm">
                            <div className="col-md-3">
                                <img src="https://imgur.com/XaTWxJX.jpg" alt="loader" style={{ maxWidth: '100%' }} />
                            </div>

                            <div className="col-md-9">
                                <div className="mt-1">&nbsp;</div>
                                <div className="hello">
                                    <h2>Hello Again!</h2>
                                    <h4>Welcome back you have been missed! </h4>
                                </div>
                                <form onSubmit={handleLogin}>

                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="Enter Email" name="email" value={username} onChange={(e) => setUsername(e.target.value)} />
                                        {!emailRegex.test(username) && username && <p className="text-danger"><i className="fa fa-warning"></i>Please enter a valid email address.</p>}
                                    </div>
                                    <div className="form-group mt-3" style={{ position: 'relative' }}>
                                        <input type={eye ? 'text': 'password'} className="form-control" placeholder="Enter Password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                        <i
                                            onClick={showPassword}
                                            style={{ position: 'absolute', top: '10px', right: '10px', zIndex: 9999 }}
                                            className={`fa ${eye ? "fa-eye-slash" : "fa-eye" }`}
                                        ></i>
                                    </div>
                                    <button className="btn btn-lg btn-success mt-3" type="submit">
                                        Login
                                    </button>
                                    
                                    <div className="register mt-2">
                                        <p className="text-dark">Not a Admin? <a className="text-info" href="/">Visit Home</a></p>
                                    </div>

                                </form>
                            </div>
                    </div>
                </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default LoginForm;
import React from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

const HomeHeroArea = () => {
   const homeSliderData = [
      {
         id: 1,
         bgImg: 'home_slider_3',
         title: 'Carbon Capture',
         description: 'Scalable bio carbon capture & utilization',
         action: 'home_slider_3',
         href: '/services/CarbonCapture/Microalgae',
      },
      {
         id: 2,
         bgImg: 'home_slider_2',
         title: 'Net Zero',
         description: 'Energy transition reimagined',
         action: 'home_slider_2',
         href: '/services/NetZeroTransition',
      },
      {
         id: 3,
         bgImg: 'home_slider_1',
         title: 'Make your manufacturing smart',
         description: 'Shape the future of manufacturing with factory 4.0',
         action: 'home_slider_1',
         href: '/services/SmartFactory/FactoryAutomation',
      }
   ]
   // slick setting
   const settings = {
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 500,
      infinite: true,
      hover: true,
      dots: true,
      fade: true,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
   };

   return (
      <>
         <section className="slider__area">
            <Slider className='slider-active' {...settings}>
               {
                  homeSliderData.map((slider, index) => {
                     return <div key={index} className={`single-slider slider__height d-flex align-items-center ${slider.bgImg}`}>
                        <div className="slider__shape">
                           <img className="shape triangle" src="assets/img/icon/slider/triangle.png" alt="triangle" />
                           <img className="shape dotted-square" src="assets/img/icon/slider/dotted-square.png" alt="dotted-square" />
                           <img className="shape solid-square" src="assets/img/icon/slider/solid-square.png" alt="solid-square" />
                           <img className="shape circle" src="assets/img/icon/slider/circle.png" alt="circle" />
                        </div>
                        <div className="container h1_slider_wrapper">
                           <div className="row">
                              <div className="col-xl-9 col-lg-9 col-md-10 col-sm-10">
                                 <Link to={slider.href}>
                                    <div className="slider__content">
                                       <span>{slider.title}</span>
                                       <h1 dangerouslySetInnerHTML={{ __html: slider.description }} />
                                    </div>
                                 </Link>
                              </div>
                           </div>
                        </div>
                     </div>
                  })
               }
            </Slider>
         </section>
      </>
   );
};

export default HomeHeroArea;
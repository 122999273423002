import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import AboutPage from './AboutPage/AboutPage';
import HomeOneHeader from '../Home/HomeOneHeader/HomeOneHeader';
import CommonCtaArea from '../../components/CommonCtaArea/CommonCtaArea';

const About = () => {
   return (
      <>
         <PageHelmet pageTitle="About Us" />
         <HomeOneHeader />
         <CommonPageHeader title="About Us" subtitle="About" />
         <AboutPage />
         <CommonCtaArea />
         <Footer />
      </>
   );
};

export default About;
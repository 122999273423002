import React from 'react';
import Slider from "react-slick";
import SingleSolutions from './FactoryAutomationData/SingleSolutions';
// import SingleService from './FactoryAutomationData/SingleService';

const settings = {
   autoplay: false,
   autoplaySpeed: 4000,
   speed: 500,
   infinite: true,
   hover: true,
   dots: true,
   fade: false,
   arrows: false,
   slidesToShow: 1,
   slidesToScroll: 1,
};

const HomeServices = () => {
   return (
      <>
         <section className="services__area black-bg pt-100 pb-50">
            <div className="container">
               <div className="row">
                  <div className="col-xl-12">
                     <div className="section__title text-center mb-50">
                        <h3 className="white-color">We have a proven combination of Factory Automation and IT Infrastructure</h3>
                     </div>
                  </div>
               </div>
               {/* <div className="row">
                  <div className="col-xl-4">
                     <div className="services__item text-center">
                        <div className="services__icon mb-30">
                           <img src="/assets/img/about-icon1.png" alt="" />
                        </div>
                        <div className="services__content">
                           <h3>Smart Factory Solutions</h3>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-4">
                     <div className="services__item text-center">
                        <div className="services__icon mb-30">
                           <img src="/assets/img/about-icon2.png" alt="" />
                        </div>
                        <div className="services__content">
                           <h3>Net Zero Transition</h3>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-4">
                     <div className="services__item text-center">
                        <div className="services__icon mb-30">
                           <img src="/assets/img/about-icon3.png" alt="" />
                        </div>
                        <div className="services__content">
                           <h3>Carbon Capture Innovation</h3>
                        </div>
                     </div>
                  </div>
               </div> */}
            </div>
         </section>
         <section className="services__area black-bg pt-50 pb-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-12">
                     <div className="section__title section__title text-center mb-20">
                        <h3 className="white-color">Discover Our Cutting-Edge Solutions</h3>
                     </div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-xl-12">
                     <Slider {...settings}>
                        <SingleSolutions serviceID="0" type="right" />
                        <SingleSolutions serviceID="1" type="right" />
                        <SingleSolutions serviceID="2" type="right" />
                        <SingleSolutions serviceID="3" type="right" />
                        <SingleSolutions serviceID="4" type="right" />
                        <SingleSolutions serviceID="5" type="right" />
                     </Slider>
                  </div>
               </div>
               {/* <div className="row">
                  <div className="col-xl-12">
                     <SingleSolutions serviceID="0" type="left" />
                     <SingleSolutions serviceID="1" type="right" />
                     <SingleSolutions serviceID="2" type="left" />
                     <SingleSolutions serviceID="3" type="right" />
                     <SingleSolutions serviceID="4" type="left" />
                     <SingleSolutions serviceID="5" type="right" />
                  </div>
               </div> */}
            </div>
         </section>

         {/* <section className="services__area black-bg pt-100 pb-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-12">
                     <div className="section__title section__title text-center mb-20">
                        <h2 className="white-color">Our Services</h2>
                     </div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-xl-12">
                     <SingleService serviceID="0" type="left" />
                     <SingleService serviceID="1" type="right" />
                     <SingleService serviceID="2" type="left" />
                  </div>
               </div>
            </div>
         </section> */}

         <section className="services__area-3 black-bg pt-100 pb-50">
            <div className="container">
               <div className="row">
                  <div className="col-xl-12">
                     <div className="section__title section__title text-center mb-20">
                        <h2 className="white-color">Why Choose Us</h2>
                        <h4 className="white-color">Your Partner in Factory Automation</h4>
                     </div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-xl-3 ">
                     <div className="services__item black2 mt-30">
                        <div className="services__content">
                           <h3>Engineering Expertise</h3>
                           <p>Benefit from our team's expertise and cutting-edge technology, delivering innovative solutions that drive your business forward.</p>
                           <p className="d-none d-lg-block"><br /><br /><br /></p>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-3 ">
                     <div className="services__item black2 mt-30">
                        <div className="services__content">
                           <h3>Tailored Solutions</h3>
                           <p>Collaborate closely with us to implement the most suitable solutions for your business, understanding your unique requirements.</p>
                           <p className="d-none d-lg-block"><br /><br /></p>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-3">
                     <div className="services__item black2 mt-30">
                        <div className="services__content">
                           <h3>Commitment to Excellence</h3>
                           <p>Quality, innovation, and customer satisfaction are at the core of everything we do, ensuring the highest standard of service.</p>
                           <p className="d-none d-lg-block"><br /><br /></p>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-3">
                     <div className="services__item black2 mt-30">
                        <div className="services__content">
                           <h3>Partnership Approach</h3>
                           <p>As a proud partner of Maestrotek & COPA Data in India, leading providers of software solutions for industrial automation, we can help you leverage the benefits of Industry 4.0 and IIoT for your production facilities.</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <section className="services__area-3 black-bg pt-100 pb-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-12">
                     <div className="section__title section__title">
                        <h3 className="next white-color mb-0">We are a partner of <b>Maestrotek & COPA Data</b> in India, a leading provider of software solutions for industrial automation, that can help you leverage the benefits of Industry 4.0 and IIoT for your production facilities.</h3>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeServices;
// https://stackoverflow.com/questions/68690135/how-to-get-the-html-content-from-react-draft-wysiwyg-from-the-child-component-to
// https://www.tiny.cloud/my-account/integrate/#react using noserverofsjain@gmail.com account
import React, { useEffect, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

const ShowHtmlEditor = ({ id, PageContent, setPageContent }) => {
    const editorRef = useRef(null);
    const log = () => {
        if (editorRef.current) {
            setPageContent(editorRef.current.getContent());
        }
    };
    useEffect(() => {
        // af
    }, [id]);

    return (
        <Editor
            apiKey='e8stt465506f4pqu7a379vb2mm8ebm90ejmpue366b0cwlky'
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={PageContent}
            init={{
            height: 500,
            menubar: true,
            plugins: [
                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
            ],
            toolbar: 'undo redo | blocks | ' +
                'bold italic forecolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            }}
            onChange={log}
        />
    );
};

export default ShowHtmlEditor;

import React from 'react';
import { FaEnvelope, FaLinkedin, FaPhoneAlt } from 'react-icons/fa'; // FaTwitter, FaFacebookF
import { BiMap } from 'react-icons/bi';
import { NavLink } from 'react-router-dom';
import { contactAddress, contactCompany, contactEmail, contactPhone } from '../../config';

const today = new Date();

const Footer = () => {
   return (
      <>
         <footer>
            <div className="footer__area black-bg pt-60">
               <div className="footer__top">
                  <div className="container">
                     <div className="row">
                        <div className="col-xl-3">
                           <div className="footer__widget">
                              <div className="footer__widget-title">
                                 <h4>Smart Factory</h4>
                              </div>
                              <div className="footer__widget-content">
                                 <div className="footer__links">
                                    <ul>
                                       <li><NavLink to="/services/SmartFactory/FrontlineAutomation">Frontline Automation</NavLink></li>
                                       <li><NavLink to="/services/SmartFactory/FactoryAutomation">Factory Automation</NavLink></li>
                                       <li><NavLink to="/services/SmartFactory/DigitalRoadmapping">Digital Road mapping</NavLink></li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-xl-3">
                           <div className="footer__widget">
                              <div className="footer__widget-title">
                                 <h4>Net Zero Transition</h4>
                              </div>
                              <div className="footer__widget-content">
                                 <div className="footer__links">
                                    <ul>
                                       <li><NavLink to="/services/NetZeroTransition">Green Hydrogen Transition</NavLink></li>
                                       <li><NavLink to="/services/NetZeroTransition">EV Charging Station</NavLink></li>
                                       <li><NavLink to="/services/NetZeroTransition">Microgrid Analysis</NavLink></li>
                                       <li><NavLink to="/services/NetZeroTransition">Ammonia Analysis and Dry Methane Reforming Yield Modeling</NavLink></li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-xl-3">
                           <div className="footer__widget">
                              <div className="footer__widget-title">
                                 <NavLink to="/services">
                                    <h4>Carbon Capture</h4>
                                 </NavLink>
                              </div>
                              <div className="footer__widget-content">
                                 <div className="footer__links">
                                    <ul>
                                       <li><NavLink to="/services/CarbonCapture/Microalgae">Microalgae</NavLink></li>
                                       {/* <li><NavLink to="/services/CarbonCapture/Membarane">Membarane</NavLink></li> */}
                                    </ul>
                                 </div>
                              </div>
                           </div>
                           {/* <div className="footer__widget">
                              <div className="footer__widget-title">
                                 <h4>Solutions</h4>
                              </div>
                              <div className="footer__widget-content">
                                 <div className="footer__links">
                                    <ul>
                                       <li><NavLink to="/services/Solutions/WorkforceForecasting">Workforce Forecasting</NavLink></li>
                                       <li><NavLink to="/services/Solutions/COGSModeling">COGS Modeling</NavLink></li>
                                       <li><NavLink to="/services/Solutions/FactoryDigitalTwin">Factory Digital Twin</NavLink></li>
                                    </ul>
                                 </div>
                              </div>
                           </div> */}
                        </div>
                        <div className="col-xl-3">
                           <div className="footer__widget">
                              <div className="footer__widget-title">
                                 <h4>Information</h4>
                              </div>
                              <div className="footer__widget-content">
                                 <div className="footer__info">
                                    <ul>
                                       <li>
                                          <div className="icon">
                                             <i> <BiMap /> </i>
                                          </div>
                                          <div className="text">
                                             <span dangerouslySetInnerHTML={{ __html: contactAddress }}></span>
                                          </div>
                                       </li>
                                       <li>
                                          <div className="icon">
                                             <i> <FaEnvelope /> </i>
                                          </div>
                                          <div className="text">
                                             <span><a href={`mailto:${contactEmail}`}>{contactEmail}</a></span>
                                          </div>
                                       </li>
                                       <li>
                                          <div className="icon">
                                             <i><FaPhoneAlt /> </i>
                                          </div>
                                          <div className="text">
                                             <span><a href={`tel:${contactPhone}`}>{contactPhone}</a></span>
                                          </div>
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                              <div className="footer__social theme-social mt-40">
                                 <ul>
                                    {/* <li>
                                       <a href="/">
                                          <i><FaFacebookF /></i>
                                          <i><FaFacebookF /></i>
                                       </a>
                                    </li>
                                    <li>
                                       <a href="/">
                                          <i><FaTwitter /> </i>
                                          <i><FaTwitter /> </i>
                                       </a>
                                    </li> */}
                                    <li>
                                       <a href="https://www.linkedin.com/company/iiot-innovation/" target="_blank" rel="noreferrer">
                                          <i><FaLinkedin /> </i>
                                          <i><FaLinkedin /> </i>
                                       </a>
                                    </li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="footer__copyright">
                  <div className="container">
                     <div className="row align-items-center">
                        <div className="col-xl-6 col-lg-6 col-md-6">
                           <div className="footer__copyright-text">
                              <b>{contactCompany} &copy; {today.getFullYear()} - All Rights Reserved.</b>
                           </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6">
                           <div className="footer__copyright-textR">
                              <b>Design & Developed by
                                 <a href="https://sjain.io/" target="_blank" rel="noopener noreferrer">
                                    <img src="/assets/img/sjain-white.png" alt="Sjain Ventures" />
                                 </a>
                              </b>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </footer>
      </>
   );
};

export default Footer;
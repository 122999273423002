import React from 'react';
import { NavLink } from 'react-router-dom';

const HomeCapabilities = () => {
   return (
      <>
         <section className="capabilities__area p-relative black-bg pt-100 pb-100 fix">
            <div
               className="capabilities__thumb p-absolute"
               style={{ background: `url(assets/img/home-bg1a.png)`, backgroundPosition: 'left center', backgroundSize: 'cover' }}>
            </div>
            <div className="container">
               <div className="row">
                  <div className="col-xl-5">
                     <div className="capabilities__content">
                        <div className="section__title section__title-2 mb-40">
                           <span className="white-color">Smarter Way to Run your Workforce</span>
                           <h2 className="white-color">Smart Factory</h2>
                           <p className="white-color">A Smart Factory is a digitised manufacturing facility that uses connected devices, machinery and production systems to continuously collect and share data. This data is then used to inform decisions to improve processes as well as address any issues that may arise.</p>
                        </div>
                        <div className="capabilities__list ">
                           <ol>
                              <li style={{ 'listStyle': 'disc' }}><NavLink to="/services/SmartFactory/FrontlineAutomation">Frontline Automation</NavLink></li>
                              <li style={{ 'listStyle': 'disc' }}><NavLink to="/services/SmartFactory/FactoryAutomation">Factory Automation</NavLink></li>
                              <li style={{ 'listStyle': 'disc' }}><NavLink to="/services/SmartFactory/DigitalRoadmapping">Digital Road Mapping</NavLink></li>
                           </ol>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeCapabilities;
import React from 'react';

const HomeServices = () => {
   return (
      <>
         <section className="services__area black-bg pt-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-12">
                     <div className="section__title text-center">
                        <h3 className="white-color">We can guide through your digital transformation journey</h3>
                        <p className="white-color">Experience the power of digital evolution with our platform, guiding global companies through their digital journey. From consulting to execution, we prioritize and implement optimal digital solutions for manufacturing excellence.</p>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <section className="services__area black-bg pt-100 pb-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-4">
                     <div className="services__item text-center">
                        <div className="services__icon mb-30">
                           <img src="/assets/img/digi-road-icon1.png" alt="" />
                        </div>
                        <div className="services__content">
                           <h3>Consulting</h3>
                           <p>Strategic guidance to enhance manufacturing performance and competitiveness.</p>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-4">
                     <div className="services__item text-center">
                        <div className="services__icon mb-30">
                           <img src="/assets/img/digi-road-icon2.png" alt="" />
                        </div>
                        <div className="services__content">
                           <h3>Maturity Assessment</h3>
                           <p>Evaluate digital maturity, identify improvement opportunities, and receive actionable steps.</p>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-4">
                     <div className="services__item text-center">
                        <div className="services__icon mb-30">
                           <img src="/assets/img/digi-road-icon3.png" alt="" />
                        </div>
                        <div className="services__content">
                           <h3>Innovation Process</h3>
                           <p>Tailored solutions designed, developed, and deployed for stakeholder value.</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <section className="services__area black-bg pt-100 pb-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-12">
                     <div className="m-img">
                        <img src="/assets/img/digitalroadmapping/banner.png" alt="" />
                     </div>
                  </div>
               </div>
            </div>
         </section>

         {/* <section className="services__area black-bg pt-100 pb-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-12">
                     <div className="section__title section__titlemb-lg-5 text-center">
                        <h2 className="white-color">Why Choose Us</h2>
                        <h5 className='text-white'>Your Trusted Partner in Digital Roadmapping</h5>
                     </div>
                  </div>
               </div>

               <div className="pt-100 pb-100">
                  <div className="row align-items-center">
                     <div className="col-xl-5">
                        <div className="m-img text-center mt-lg-0 mt-30">
                           <img src="/assets/img\digitalroadmapping/exp.webp" alt="" />
                        </div>
                     </div>
                     <div className="col-xl-1"></div>
                     <div className="col-xl-6">
                        <div className="section__title section__title">
                           <h2 className="white-color mb-30">Experience</h2>
                           <h4 className="white-color mb-0">Over 40 years of digital innovation expertise in manufacturing.</h4>
                        </div>
                     </div>
                  </div>
               </div>

               <div className="pt-100 pb-100">
                  <div className="row align-items-center">
                     <div className="col-xl-6">
                        <div className="section__title section__title">
                           <h2 className="white-color mb-30">Comprehensive Services</h2>
                           <h4 className="white-color mb-0">Offering a range of services for your digital journey.</h4>
                        </div>
                     </div>
                     <div className="col-xl-1"></div>
                     <div className="col-xl-5">
                        <div className="m-img text-center mt-lg-0 mt-30">
                           <img src="/assets/img/digitalroadmapping/comp.webp" alt="" />
                        </div>
                     </div>
                  </div>
               </div>


               <div className="pt-100 pb-100">
                  <div className="row align-items-center">
                     <div className="col-xl-5">
                        <div className="m-img text-center mt-lg-0 mt-30">
                           <img src="/assets/img/digitalroadmapping/part.webp" alt="" />
                        </div>
                     </div>
                     <div className="col-xl-1"></div>
                     <div className="col-xl-6">
                        <div className="section__title section__title">
                           <h2 className="white-color mb-30">Partnership Approach</h2>
                           <h4 className="white-color mb-0">Your dedicated partners in achieving digital goals.</h4>
                        </div>
                     </div>
                  </div>
               </div>

            </div>
         </section> */}

         <section className="services__area-3 black-bg pt-100 pb-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-12">
                     <div className="section__title section__title text-center mb-lg-5">
                        <h2 className="white-color">Our Approach</h2>
                        <h5 className='text-white'>Charting Your Path to Success</h5>
                     </div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-xl-4 mb-3 mb-lg-0">
                     <div className="services__item showArrowDown black2">
                        {/* <div className='showArrowDown'></div> */}
                        <div className="services__content">
                           <h3>Process Mapping</h3>
                           <ul className="li_ry">
                              <li>Capture current steps</li>
                              <li>identify gaps</li>
                              <li>envision the future state</li>
                           </ul>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-4 mb-3 mb-lg-0">
                     <div className="services__item black2 showArrowUp">
                        <div className="services__content">
                           <h3>Data Analysis</h3>
                           <ul className="li_ry">
                              <li>Enhance Performance</li>
                              <li>Enhance Quality</li>
                              <li>Efficiency through data-driven insights</li>
                           </ul>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-4">
                     <div className="services__item black2 showArrowDown">
                        <div className="services__content">
                           <h3>Scaling Up</h3>
                           <ul className="li_ry">
                              <li>Expand smart factory capabilities</li>
                              <li>Prioritize use cases for success</li>
                              <li style={{ opacity: 0 }}>&nbsp;</li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeServices;
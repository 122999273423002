// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo img {
    max-width: 140px;
}
.logo-gradient img {
    max-width: 130px;
}
.adminForm {
    border: 2px solid #f5f5f5;
    padding: 30px;
    margin-top: 30px !important;
    margin-bottom: 30px
}
.DraftEditor-editorContainer {
    max-height: 400px;
    overflow-y: auto;
    border: 1px solid #f5f5f5;
    padding: 0px 10px;
    margin-bottom: 20px;
}
.img-responsive {
    max-width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,yBAAyB;IACzB,aAAa;IACb,2BAA2B;IAC3B;AACJ;AACA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,yBAAyB;IACzB,iBAAiB;IACjB,mBAAmB;AACvB;AACA;IACI,eAAe;AACnB","sourcesContent":[".logo img {\n    max-width: 140px;\n}\n.logo-gradient img {\n    max-width: 130px;\n}\n.adminForm {\n    border: 2px solid #f5f5f5;\n    padding: 30px;\n    margin-top: 30px !important;\n    margin-bottom: 30px\n}\n.DraftEditor-editorContainer {\n    max-height: 400px;\n    overflow-y: auto;\n    border: 1px solid #f5f5f5;\n    padding: 0px 10px;\n    margin-bottom: 20px;\n}\n.img-responsive {\n    max-width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import LoginForm from './LoginForm';
import ListBlogs from './ListBlogs';
import HomeOneHeader from '../Home/HomeOneHeader/HomeOneHeader';
import { AdminLoginCheckURL } from '../../config';
import axios from 'axios';

const AdminDashboard = () => {
   const [IsLogin, SetIsLogin] = useState('');
   let { pageID } = useParams();
   if (!pageID) {
      pageID = "listBlogs";
   }
   const AdminLoginCheck = async (token) => {
      // Handle login logic here
      try {
          // Make API call using Axios
         const headers = {
            'loggedToken': `Basic ${token}`,
            'Content-Type': 'application/json', // Adjust content type based on your API requirements
         };
          const response = await axios.post(AdminLoginCheckURL, { token }, { headers });
          // Handle successful login (use the response data as needed)
          if (Number(response.data.status) === 1 && response.data.token && response.data.token !== '0' && response.data.token !== '' ) {
              SetIsLogin(true);
          } else {
              SetIsLogin(false);
          }
        } catch (error) {
          // Handle login failure
          console.error('Login error:', error.response.data);
        }
  };

   useEffect(() => {
      AdminLoginCheck(localStorage.getItem('adminToken'));
   }, []);

   return (
      <>
         {IsLogin === '' ? (
            <center>Loging Panel</center>
         ) : (
            <>
               <PageHelmet pageTitle="Administrator" />
               <HomeOneHeader/>
               {IsLogin ? (
                  <>
                     <CommonPageHeader title="Administrator Dashboard" subtitle="Dashboard" />
                     {pageID && ( <ListBlogs SetIsLogin={SetIsLogin} /> ) }
                  </>
               ) : (
                  <>
                     <CommonPageHeader title="Administrator Login" subtitle="Login" />
                     <LoginForm SetIsLogin={SetIsLogin} />
                  </>
               )}
               <Footer/>
            </>
         )}
      </>
   );
};

export default AdminDashboard;
import React from 'react';
import { Link } from 'react-router-dom';
import { CgArrowLongRight } from 'react-icons/cg';

const HomeAbout = () => {
   return (
      <>
         <section className="about__area black-bg pb-100 pt-100">
            <div className="container-fluid">
               <div className="row align-items-center">
                  <div className="col-xl-5">
                     <div className="about__thumb m-img r-img">
                        <img src="assets/img/home-img1-new.jpg" alt="" />
                     </div>
                  </div>
                  <div className="col-xl-7">
                     <div className="ms-lg-5">
                        <div className="about__content mb-40">
                           <div className="section__title mb-25">
                              <h3 className="white-color">Empowering Industries, Envisioning Sustainability</h3>
                           </div>
                           <p className="white-color mb-0">Welcome to IIOT Innovation, where the future of industrial evolution is being redefined. Our mission is simple yet profound: to lead the charge in creating a sustainable and efficient future for industries in the era of Industry 4.0.</p>
                        </div>
                        <div className="capabilities__content">
                           <div className="section__title section__title-2 mb-30">
                              <h4 className="white-color">Our Three Core Pillars</h4>
                           </div>
                           <div className="capabilities__list ">
                              <ol className="about">
                                 <li>Smart Factory Solutions</li>
                                 <li>Net Zero Transition</li>
                                 <li>Carbon Capture Innovation</li>
                              </ol>
                           </div>
                        </div>
                        <div className="mt-40">
                           <Link to="/about" className="z-btn z-btn-white">Learn More <i><CgArrowLongRight /></i></Link>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeAbout;